import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  getAllfactories,
  getUnitsandMeters,
} from "../../utils/authenticationUtils";
import { MultiSelect } from "react-multi-select-component";
import { useLocation, useNavigate } from "react-router-dom";
import { updateData } from "../../api/apiDataExchange";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";

const UserRoleManagement = () => {
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [apiData, setApiData] = useState(null);
  const [states, setStates] = useState([]);
  const [discoms, setDiscoms] = useState([]);
  const [units, setUnits] = useState([]);
  const [meters, setMeters] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedDiscoms, setSelectedDiscoms] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [accessLevel, setAccessLevel] = useState("state");
  const [loading, setLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userid = queryParams.get("userid");
  const navigate = useNavigate();
  const [isUploadBillEnabled, setIsUploadBillEnabled] = useState(false); // State for the switch

  const handleUploadBillToggle = () => {
    setIsUploadBillEnabled((prev) => !prev);
    console.log("Upload Bill Toggled:", !isUploadBillEnabled);
  };

  useEffect(() => {
    setLoading(true);
    const getOrganisationData = async () => {
      const { organisationId } = await getUnitsandMeters();
      if (organisationId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/organisation/tree/${organisationId}`
          );
          const data = response.data.data;
          setApiData(data); // Store the organisation data

          // Set unique States list from Organisation Data
          const uniqueStates = [
            ...new Map(
              data.factories.flatMap((factory) =>
                factory.units.map((unit) => [
                  unit.state.code,
                  { label: unit.state.code, value: unit.state.code },
                ])
              )
            ).values(),
          ];
          setStates(uniqueStates);

          if (userid) {
            const userResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/user/${userid}`
            );
            setLoading(false);
            const userData = userResponse.data?.result;
            if (userData) {
              const { email, username, uploadBill } = userData;
              setEmail(email);
              setUsername(username);
              setIsUploadBillEnabled(uploadBill);

              // Prepopulate selected states based on user data
              const userAccessRole = userData.accessRole || {};
              const userStates = userAccessRole.states.map((stateCode) => {
                return { label: stateCode, value: stateCode };
              });
              setSelectedStates(userStates); // Set initial selected states
              console.log({ uniqueStates, userStates });

              // 2. Set Discoms Based on User's Selected States
              const selectedStateCodes = userStates.map((state) => state.value);
              const stateData = data.factories
                .flatMap((factory) => factory.units)
                .filter((unit) => selectedStateCodes.includes(unit.state.code));

              const uniqueDiscoms = [];
              console.log({ stateData });

              selectedStateCodes.forEach((stateCode) => {
                const discomCodes = stateData
                  .filter((unit) => unit.state.code === stateCode)
                  .map((unit) => unit.discomCode); // Get discom codes for the selected state

                discomCodes.forEach((discomCode) => {
                  // Add discom to uniqueDiscoms if it's not already there
                  if (
                    !uniqueDiscoms.some((discom) => discom.value === discomCode)
                  ) {
                    uniqueDiscoms.push({
                      label: discomCode,
                      value: discomCode,
                    });
                  }
                });
              });
              setDiscoms(uniqueDiscoms); // Set unique discoms
              let selectedDiscoms;
              if (userAccessRole.discoms) {
                selectedDiscoms = userAccessRole.discoms.map((discom) => {
                  return { label: discom, value: discom };
                });

                setSelectedDiscoms(selectedDiscoms);
              }
              // 3. Set Units Based on User's Selected Discoms
              const selectedDiscomCodes = selectedDiscoms.map(
                (discom) => discom.value
              );
              // All selected discoms
              console.log({ selectedDiscomCodes });

              const discomData = stateData.filter((unit) =>
                selectedDiscomCodes.includes(unit.discomCode)
              );
              console.log({ discomData });
              let selectedUnits;
              if (userAccessRole.units) {
                const uniqueUnits = [];
                discomData.forEach((unit) => {
                  uniqueUnits.push({ label: unit.name, value: unit.name });
                });

                // Remove duplicates by using Map
                const uniqueUnitsSet = [
                  ...new Map(
                    uniqueUnits.map((unit) => [unit.value, unit])
                  ).values(),
                ];
                setUnits(uniqueUnitsSet); // Set unique units
                selectedUnits = userAccessRole.units
                  .map((unitId) => {
                    const matchingUnit = data.factories
                      .flatMap((factory) => factory.units)
                      .find((unit) => unit._id === unitId); // Match unit by ID
                    return matchingUnit
                      ? { label: matchingUnit.name, value: matchingUnit.name }
                      : null;
                  })
                  .filter((unit) => unit !== null); // Remove null values for unmatched IDs

                setSelectedUnits(selectedUnits);

                const selectedUnitNames = selectedUnits.map(
                  (unit) => unit.value
                ); // All selected units
                const unitData = discomData.filter((unit) =>
                  selectedUnitNames.includes(unit.name)
                );

                const uniqueMeters = [];
                unitData.forEach((unit) => {
                  unit.meters.forEach((meter) => {
                    uniqueMeters.push({
                      label: meter.formattedConsumerName,
                      value: meter.formattedConsumerName,
                    });
                  });
                });

                // Remove duplicates by using Map
                const uniqueMetersSet = [
                  ...new Map(
                    uniqueMeters.map((meter) => [meter.value, meter])
                  ).values(),
                ];
                setMeters(uniqueMetersSet); // Set unique meters
              }

              if (userAccessRole.meters) {
                const selectedMeters = userAccessRole.meters
                  .map((meterId) => {
                    const matchingMeter = data.factories
                      .flatMap((factory) => factory.units)
                      .flatMap((unit) => unit.meters)
                      .find((meter) => meter._id === meterId); // Match meter by ID
                    return matchingMeter
                      ? {
                          label: matchingMeter.formattedConsumerName,
                          value: matchingMeter.formattedConsumerName,
                        }
                      : null;
                  })
                  .filter((meter) => meter !== null); // Remove null values for unmatched IDs

                setSelectedMeters(selectedMeters); // Set the selected meters based on user data
                console.log({ userAccessRole });
              }
              console.log({ userAccessRole });
              // Set the access level based on user's role
              if (userAccessRole.meters) {
                setAccessLevel("consumerNo");
              } else if (userAccessRole.units) {
                setAccessLevel("unit");
              } else if (userAccessRole.discoms) {
                setAccessLevel("discom");
              } else {
                setAccessLevel("state");
              }
            }
          }
        
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    getOrganisationData();
  }, [userid]);

  useEffect(() => {
    setIsFormValid(checkIsFormValid());
  }, [
    accessLevel,
    selectedStates,
    selectedDiscoms,
    selectedUnits,
    selectedMeters,
  ]);

  const handleStateChange = (states) => {
    setSelectedStates(states);
    setSelectedDiscoms([]);
    setSelectedUnits([]);
    setSelectedMeters([]);

    const selectedStateValues = states.map((state) => state.value);

    const filteredDiscoms = [
      ...new Map(
        apiData.factories
          .flatMap((factory) => factory.units)
          .filter((unit) => selectedStateValues.includes(unit.state.code))
          .map((unit) => [
            unit.discomCode,
            { label: unit.discomCode, value: unit.discomCode },
          ])
      ).values(),
    ];
    setDiscoms(filteredDiscoms);
  };

  const handleDiscomChange = (discoms) => {
    setSelectedDiscoms(discoms);
    setSelectedUnits([]);
    setSelectedMeters([]);

    const selectedDiscomValues = discoms.map((d) => d.value);

    const filteredUnits = [
      ...new Map(
        apiData.factories
          .flatMap((factory) => factory.units)
          .filter(
            (unit) =>
              selectedStates.map((s) => s.value).includes(unit.state.code) &&
              selectedDiscomValues.includes(unit.discomCode)
          )
          .map((unit) => [unit.name, { label: unit.name, value: unit.name }])
      ).values(),
    ];
    setUnits(filteredUnits);
  };

  const handleUnitChange = (units) => {
    setSelectedUnits(units);
    setSelectedMeters([]);

    const selectedUnitValues = units.map((u) => u.value);

    const filteredMeters = [
      ...new Map(
        apiData.factories
          .flatMap((factory) => factory.units)
          .filter(
            (unitItem) =>
              selectedStates
                .map((s) => s.value)
                .includes(unitItem.state.code) &&
              selectedDiscoms
                .map((d) => d.value)
                .includes(unitItem.discomCode) &&
              selectedUnitValues.includes(unitItem.name)
          )
          .flatMap((unitItem) =>
            unitItem.meters.map((meter) => [
              meter.formattedConsumerName,
              {
                label: meter.formattedConsumerName,
                value: meter.formattedConsumerName,
              },
            ])
          )
      ).values(),
    ];
    setMeters(filteredMeters);
  };

  const handleRadioChange = (e) => {
    setAccessLevel(e.target.value);
    setSelectedStates([]);
    setSelectedDiscoms([]);
    setSelectedUnits([]);
    setSelectedMeters([]);
  };

  const handleSubmit = () => {
    const saveData = async () => {
      try {
        const accessRole = {};

        if (accessLevel === "state") {
          accessRole.states = selectedStates.map((state) => state.value);
        } else if (accessLevel === "discom") {
          accessRole.states = selectedStates.map((state) => state.value);
          accessRole.discoms = selectedDiscoms.map((discom) => discom.value);
        } else if (accessLevel === "unit") {
          accessRole.states = selectedStates.map((state) => state.value);
          accessRole.discoms = selectedDiscoms.map((discom) => discom.value);

          // Map selected units to their IDs
          const unitIds = selectedUnits
            .map((unit) => {
              const matchingUnit = apiData.factories
                .flatMap((factory) => factory.units)
                .find((u) => u.name === unit.value);
              console.log({ matchingUnit });

              return matchingUnit?._id; // Get the ID if matching unit is found
            })
            .filter((id) => id); // Remove undefined values if no match
          accessRole.units = unitIds;
        } else if (accessLevel === "consumerNo") {
          accessRole.states = selectedStates.map((state) => state.value);
          accessRole.discoms = selectedDiscoms.map((discom) => discom.value);

          // Map selected units to their IDs
          const unitIds = selectedUnits
            .map((unit) => {
              const matchingUnit = apiData.factories
                .flatMap((factory) => factory.units)
                .find((u) => u.name === unit.value);
              return matchingUnit?._id; // Get the ID if matching unit is found
            })
            .filter((id) => id); // Remove undefined values if no match
          accessRole.units = unitIds;

          // Map selected meters to their IDs
          const meterIds = selectedMeters
            .map((meter) => {
              const matchingMeter = apiData.factories
                .flatMap((factory) => factory.units)
                .flatMap((unit) => unit.meters)
                .find((m) => m.formattedConsumerName === meter.value);
              return matchingMeter?._id; // Get the ID if matching meter is found
            })
            .filter((id) => id); // Remove undefined values if no match
          accessRole.meters = meterIds;
        }

        const updatedData = await updateData(
          `${process.env.REACT_APP_API_URL}/user/${userid}`,
          { accessRole, uploadBill: isUploadBillEnabled }
        );

        if (updatedData?.data.error?.code === 200) {
          navigate("/manage-users");
        }
      } catch (error) {
        console.error("Error saving data:", error);
      }
    };

    saveData();
  };

  const checkIsFormValid = () => {
    if (accessLevel === "state") {
      return selectedStates.length > 0;
    } else if (accessLevel === "discom") {
      return selectedStates.length > 0 && selectedDiscoms.length > 0;
    } else if (accessLevel === "unit") {
      return (
        selectedStates.length > 0 &&
        selectedDiscoms.length > 0 &&
        selectedUnits.length > 0
      );
    } else if (accessLevel === "consumerNo") {
      return (
        selectedStates.length > 0 &&
        selectedDiscoms.length > 0 &&
        selectedUnits.length > 0 &&
        selectedMeters.length > 0
      );
    }
    return false;
  };

  return (
    <div className="max-w-[1920px] mt-[100px] m-auto flex">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div
        className={`${
          isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"
        } w-full flex flex-col justify-center items-center transition-width duration-300 p-4`}
      >
        <div className="bg-white shadow-lg rounded-lg p-6 w-full sm:w-[600px] sm:mb-[340px]">
          <div
            className="cursor-pointer flex justify-end "
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon
              className="bg-gray-200 flex justify-center  px-2 py-1 items-center  rounded-md"
              icon={faTimes}
            />
          </div>
          <h3 className="text-xl font-bold mb-4 text-center">Role Access</h3>

          {!loading?

          <div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">
                User email{" "}
              </label>
              <input
                className="border border-gray-300 w-full p-2 bg-gray-200 rounded-md"
                type="email"
                disabled
                value={email}
              />
            </div>
            <div className="flex items-center   mb-6">
              <span className="text-gray-700 font-medium">Upload Bill</span>

              <div
                style={{
                  backgroundColor: isUploadBillEnabled ? "green" : "red",
                }}
                className={`relative ml-8 w-11 h-6 flex items-center bg-gray-200 rounded-full p-1 cursor-pointer transition-all duration-300 ${
                  isUploadBillEnabled ? "bg-blue-500" : ""
                }`}
                onClick={handleUploadBillToggle}
              >
                <div
                  className={`w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                    isUploadBillEnabled ? "translate-x-5" : ""
                  }`}
                ></div>
              </div>
              <span className="text-gray-700  ml-2  font-medium">
                {isUploadBillEnabled ? "ON" : "OFF"}
              </span>
            </div>
            {/* Radio Buttons for Access Level */}
            <div className=" mb-4">
              <label className="block text-sm font-medium mb-1">
                Access Level
              </label>
              <div className="grid grid-cols-2 sm:grid-cols-5 gap-2 items-center mb-2">
                <div>
                  <input
                    type="radio"
                    id="state"
                    name="accessLevel"
                    value="state"
                    checked={accessLevel === "state"}
                    onChange={handleRadioChange}
                  />
                  &nbsp;{" "}
                  <label htmlFor="state" className="mr-4">
                    State
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="discom"
                    name="accessLevel"
                    value="discom"
                    checked={accessLevel === "discom"}
                    onChange={handleRadioChange}
                  />
                  &nbsp;{" "}
                  <label htmlFor="discom" className="mr-4">
                    Discom
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="unit"
                    name="accessLevel"
                    value="unit"
                    checked={accessLevel === "unit"}
                    onChange={handleRadioChange}
                  />
                  &nbsp;
                  <label htmlFor="unit" className="mr-4">
                    Unit
                  </label>
                </div>
                <div className="flex items-center col-span-2">
                  <input
                    type="radio"
                    id="consumerNo"
                    name="accessLevel"
                    value="consumerNo"
                    checked={accessLevel === "consumerNo"}
                    onChange={handleRadioChange}
                  />
                  &nbsp;{" "}
                  <label htmlFor="consumerNo" className="mr-1">
                    Consumer Number
                  </label>
                </div>
              </div>
            </div>

            {/* State Dropdown */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">
                Select States
              </label>
              <MultiSelect
                options={states}
                value={selectedStates}
                onChange={handleStateChange}
                labelledBy="Select States"
              />
            </div>

            {/* Discom Dropdown */}
            {accessLevel === "discom" ||
            accessLevel === "unit" ||
            accessLevel === "consumerNo" ? (
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  Select Discoms
                </label>
                <MultiSelect
                  options={discoms}
                  value={selectedDiscoms}
                  onChange={handleDiscomChange}
                  labelledBy="Select Discoms"
                />
              </div>
            ) : null}

            {/* Unit Dropdown */}
            {accessLevel === "unit" || accessLevel === "consumerNo" ? (
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  Select Units
                </label>
                <MultiSelect
                  options={units}
                  value={selectedUnits}
                  onChange={handleUnitChange}
                  labelledBy="Select Units"
                />
              </div>
            ) : null}

            {/* Meter Dropdown */}
            {accessLevel === "consumerNo" ? (
              <div className="mb-6">
                <label className="block text-sm font-medium mb-1">
                  Select Consumers
                </label>
                <MultiSelect
                  options={meters}
                  value={selectedMeters}
                  onChange={setSelectedMeters}
                  labelledBy="Select Consumers"
                />
              </div>
            ) : null}

            {/* Submit Button */}
            <button
              type="submit"
              className={`w-full py-2 rounded-md ${
                isFormValid
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600 cursor-not-allowed"
              }`}
              onClick={handleSubmit}
              disabled={!isFormValid}
            >
              Save
            </button>
          </div> : <div className="min-h-[50vh] flex justify-center items-center">
              <LoaderComponent />
            </div>}
        </div>
      </div>
    </div>
  );
};

export default UserRoleManagement;
