"use client";
import { useState, useEffect } from "react";
import { FormEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faCamera,
  faCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteImageById,
  getImageById,
  uploadImage,
} from "../../services/apis/media";
import { createUser, sendCredentialEmail, sendOTP, sendWelcomeEmail } from "../../services/apis/user";
import SignUpPopup from "../../components/popup/SignUpPopUp";
import { useLocation, useNavigate } from "react-router-dom";
import { postData } from "../../api/apiDataExchange";
import { useAuth } from "../../hooks/useAuth";
import Sidebar from "../../components/sidebar/Sidebar";
import { getUserOrgId } from "../../utils/authenticationUtils";

const AddNewUser = () => {
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [openPopup, setOpenPopup] = useState(false);
  const [affirmation, setAffirmation] = useState(false);
  const [userId, setUserId] = useState("");
  const [isUserInvited, setIsUserInvited] = useState(true);
  const [imageId, setImageId] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [photoUrl, setPhotoUrl] = useState("");
  const [govtIdUrl, setGovtIdUrl] = useState("");
  const [companyIdUrl, setCompanyIdUrl] = useState("");
  const [orgId, setOrgId] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    companyName: "",
    PAN: "",
    GST: "",
    designation: "",
    email: "",
    website: "",
    mobile: "",
    landline: "",
    password: "",
    confirmPassword: "",
    // photo: null,
    // companyId: null,
    // govtId: null
  });

  const [errors, setErrors] = useState({});

  const handleOpenPopup = () => {
    setOpenPopup(!openPopup);
  };

  useEffect(() => {
    getUserOrg().then((response) => {
        setOrgId(response);
      });
    
  }, []);

  const getUserOrg=async() => {
    const response = await getUserOrgId();
    return response;

  }

  useEffect(() => {
    const getdata = async () => {
      const payload = {
        id: orgId,
      };
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        payload
      );

      if (response?.data?.data?.results?.length > 0) {
        console.log({ orgData: response?.data?.data?.results });

        setFormData({
          ...formData,
          companyName: response?.data?.data?.results[0].name,
        });
      }
    };
    getdata();
  }, [orgId]);
  const handleChange = async (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };
    
    setFormData({
      ...formData,
      [name]: value,
    });
    // }
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!formData.username.trim()) {
      newErrors.username = "Name is required";
    }
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company Name is required";
    }
    if (!formData.designation.trim()) {
      newErrors.designation = "Designation is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Company Email is required";
    }
    if (!formData.PAN.trim() && !isUserInvited) {
      newErrors.PAN = "Company PAN No is required";
    }

    if (!formData.GST.trim() && !isUserInvited) {
      newErrors.GST = "Company GST No is required";
    }
    if (!formData.mobile.trim()) {
      newErrors.mobile = "Mobile Number is required";
    }






    setErrors(newErrors);
    console.log(newErrors);

    if (Object.keys(newErrors).length === 0) {
      let payload;
      const password = generateTemporaryPassword(); 
      if (isUserInvited) {
        payload = {
          ...formData,
          status: "active",
          isUserInvited,
          userType: ["user"],
          password,
          orgId,
        };
      } else {
        payload = {
          ...formData,
          status: "active",
          isUserInvited,
          userType: ["admin"],
        };
      }

      try {
        const response = await createUser(payload);

        if (response.success) {
          // setFormData({
          //     username: "",
          //     companyName: "",
          //     designation: "",
          //     email: "",
          //     website: "",
          //     mobile: "",
          //     landline: "",
          //     password: "",
          //     confirmPassword: "",
          //     photo: null,
          //     companyId: null,
          //     govtId: null
          // })
          setUserId(response?.result?._id);
          setErrors({});
          setOpenPopup(true);

          try {
            // const otpResponse = await sendOTP(response?.result?._id);
            if (response.success) {
              toast.success("User Created Successfully", {
                position: "top-center",
              });
            const sendEmail = await sendCredentialEmail(response?.result?._id,password);
              navigate("/manage-users");
            } else {
              console.error("Error creating  user:", response.error);
            }
          } catch (error) {
            console.error("Error creating  user:", error);
          }
        } else {
          if (response["response"]["data"]["error"]["message"]) {
            console.error(
              "Error creating user:",
              response["response"]["data"]["error"]["message"]
            );
            toast.error(response["response"]["data"]["error"]["message"], {
              position: "top-center",
            });
          }
        }
      } catch (error) {
        console.error("Error creating user:", error);
        toast.error("Error creating user. Please try again later.", {
          position: "top-center",
        });
      }
    } else {
      console.log("Form submission failed. Please fix the errors.");
      // toast.error('Please fix the form errors before submitting.', {
      //     position:"top-center"
      // });
    }
  };

  const generateTemporaryPassword=()=> {
    const length = 8; // Minimum length

    // Define character pools
    const lowerCase = "abcdefghijklmnopqrstuvwxyz";
    const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*";

    // Ensure at least one character from each required pool
    const randomLower = lowerCase[Math.floor(Math.random() * lowerCase.length)];
    const randomUpper = upperCase[Math.floor(Math.random() * upperCase.length)];
    const randomNumber = numbers[Math.floor(Math.random() * numbers.length)];
    const randomSymbol = symbols[Math.floor(Math.random() * symbols.length)];

    // Ensure at least 6 alphabetic characters
    let alphabeticCount = 6;
    let alphabeticChars = "";
    for (let i = 0; i < alphabeticCount; i++) {
        alphabeticChars += (Math.random() < 0.5 ? lowerCase : upperCase)[Math.floor(Math.random() * (Math.random() < 0.5 ? lowerCase : upperCase).length)];
    }

    // Combine all pools and generate the remaining characters
    const allCharacters = lowerCase + upperCase + numbers + symbols;
    let password = randomLower + randomUpper + randomNumber + randomSymbol + alphabeticChars;

    for (let i = password.length; i < length; i++) {
        password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    // Shuffle the password to ensure randomness
    password = password.split('').sort(() => 0.5 - Math.random()).join('');

    // Ensure password matches the password_regx
    const passwordRegx = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
    while (!passwordRegx.test(password)) {
        password = generateTemporaryPassword(); // Regenerate if invalid
    }

    return password;

}


  return (
    <div className="max-w-[1920px] mt-[64px]  sm:mt-[64px] m-auto flex">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div
        className={`${
          isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"
        } w-full flex flex-col justify-center items-center transition-width duration-300 sm:p-4`}
      >
        <div className="max-w-[1200px] m-auto w-full ">
          <div className="sm:mt-[36px] md:mt-[27px] sm:mx-[20px]  xl:mx-[250px] pb-[32px] md:pb-[50px] xl:mt-[68px] bg-[#E2F5EC] rounded-[20px] shadow-md flex  justify-center ">
            <div className="w-full p-4 sm:p-0">
              <div
                id="titleText"
                className="text-[#067C4E] mt-[31px]   md:mt-[38px] xl:mt-[41px] text-[24px] font-medium leading-[36px] text-center"
              >
                Add New User
              </div>

              <div className="mt-[32px] w-full md:px-[115px] xl:px-[84px]">
                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                  Enter User Name<span className="text-red-600"> *</span>
                </div>
                <div className="mt-[12px]">
                  <input
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    placeholder="Enter User Name"
                    className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                    type="text"
                  />
                  <br />
                  {errors.username && (
                    <p className="text-red-500 m-[10px] mt-[1px] absolute">
                      {errors.username}
                    </p>
                  )}
                </div>
              </div>
              <div className={` mt-[32px] w-full md:px-[115px] xl:px-[84px]`}>
                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                  Company Name<span className="text-red-600"> *</span>
                </div>
                <div className="mt-[12px]">
                  <input
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    placeholder="Enter Company Name"
                    disabled={isUserInvited}
                    className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                    type="text"
                  />
                  <br />
                  {errors.companyName && (
                    <p className="text-red-500 m-[10px] mt-[1px] absolute">
                      {errors.companyName}
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-[32px] w-full md:px-[115px] xl:px-[84px]">
                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                  Designation<span className="text-red-600"> *</span>
                </div>
                <div className="mt-[12px]">
                  <input
                    name="designation"
                    value={formData.designation}
                    onChange={handleChange}
                    placeholder="Enter Designation"
                    className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                    type="text"
                  />
                  <br />
                  {errors.designation && (
                    <p className="text-red-500 m-[10px] mt-[1px] absolute">
                      {errors.designation}
                    </p>
                  )}
                </div>
              </div>
              <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[84px]">
                <div className="mt-[32px] w-full">
                  <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                    User Email<span className="text-red-600"> *</span>
                  </div>
                  <div className="mt-[12px]">
                    <input
                      name="email"
                      value={formData.email}
                      placeholder="Enter User Email"
                      className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                      type="email"
                      onChange={handleChange}
                    />
                    <br />
                    {errors.email && (
                      <p className="text-red-500 m-[10px] mt-[1px] absolute">
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    isUserInvited ? "hidden " : "relative "
                  } mt-[32px] w-full `}
                >
                  <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                    Website (Same as Email Domain)
                  </div>
                  <div className="mt-[12px]">
                    <input
                      name="website"
                      value={formData.website}
                      placeholder="Enter Website"
                      className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                      type="text"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[84px]">
                <div className="mt-[32px] w-full ">
                  <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                    Mobile<span className="text-red-600"> *</span>
                  </div>
                  <div className="mt-[12px] mobile-input">
                    <input
                      name="mobile"
                      value={formData.mobile}
                      placeholder="Enter Mobile Number"
                      className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9] no-spinner"
                      type="number"
                      onChange={handleChange}
                    />
                    {errors.mobile && (
                      <p className="text-red-500 m-[10px] mt-[1px] absolute">
                        {errors.mobile}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    isUserInvited ? "hidden " : "relative "
                  } mt-[32px] w-full`}
                >
                  <div className="text-black text-[14px] leading-[21px] font-normal ml-[10px]">
                    Company Landline Number
                  </div>
                  <div className="mt-[12px]">
                    <input
                      name="landline"
                      value={formData.landline}
                      placeholder="Enter Company Landline Number"
                      className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                      type="email"
                      onChange={handleChange}
                    />
                    <br />
                  </div>
                </div>
              </div>
            
              

              <div className=" md:px-[115px] mt-[32px] xl:px-[184px]">
                <div className="w-fit mx-auto hidden  items-center">
                  <button
                    className="cursor-pointer bg-[#067C4E] text-white md:px-4 md:py-2 px-2 py-1  rounded-lg mr-4"
                    // onClick={handleDownload}
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faCircleDown}
                        className="h-6 w-6 mr-4"
                      />
                    </span>
                    Download Letter Head Format
                  </button>
                </div>

                <div className="mt-[32px] flex justify-center">
                {}
                  <button

                  style={errors?.keys?.length > 0 ? {backgroundColor: "#B5C6BF"} : {backgroundColor: "#067C4E"}}
                    onClick={handleSubmit}
                    className={`h-[44px] rounded-[100px] py-[10px] px-[25px] text-white text-[16px] leading-[24px] 
                     "bg-[#067C4E]"`}
                  >
                    Add User
                  </button>
                </div>
              </div>
            </div>
          </div>
    {/*       {openPopup && (
            <SignUpPopup
              setOpenPopup={setOpenPopup}
              openPopUp={openPopup}
              userId={userId}
              handleOpenPopup={handleOpenPopup}
            />
          )} */}
          <ToastContainer position="top-center" autoClose={3000} />
        </div>
      </div>
    </div>
  );
};

export default AddNewUser;
