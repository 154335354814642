import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { ConstantUtils } from "../../../utils/ConstantUtils";
import ASelect from "../../../components/select/ASelect";
import { BillConsumptionService } from "../../../services/graphs/billConsumptionService";
import {
  faEdit,
  faDownload,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderComponent from "../../../components/loaderCompent/LoaderComponent";
import UploadExelPopUp from "../../../components/popup/UploadExelPopUp";
import { useNavigate } from "react-router-dom";
import CustomTooltop from "../../../components/tooltips/CustomTooltop";
import LegendComponent from "../../../components/chart/LegendComponent";
import BackButton from "../../../components/backButton/BackButton";
import Sidebar from "../../../components/sidebar/Sidebar";
const options = {
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
      },
      grid: {
        display: false,
      },
      position: "bottom",
    },
    y: {
      stacked: true,
      type: "linear",
      display: true,
      position: "left",
      title: {
        display: true,
        text: "Energy (kwh)",
      },
      grid: {
        display: false,
      },

      ticks: {
        callback: function (value) {
          if (value >= 1000) {
            return value / 1000 + "k"; // Converts 1000 to 1k, 2000 to 2k, etc.
          }
          return value;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

//console.log({ options });

const constantUtils = new ConstantUtils();
const billService = new BillConsumptionService();

const getCurrentYear = () => {
  return new Date().getFullYear().toString();
};

const getPreviousMonth = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 3;
  return Object.keys(constantUtils.getAllStaticMonths())[previousMonth];
};

const CustomisedDataDayView = ({
  selectedGraphType,
  handleCustomDataVisualizationgraph,
}) => {
  const constantUtils = new ConstantUtils();
  const fileInputRef = useRef(null);

  const [dataArray, setDataArray] = React.useState([]);
  const [organisationList, setOrganisationList] = useState([]);
  const [organisationTreeData, setOrganisationTreeData] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [factoryList, setFactoryList] = useState([]);
  const [selectedFactory, setSelectedFactory] = useState("");
  const [unitList, setUnitList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [meterList, setMeterList] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState("");
  const [monthlist, setMonthlist] = useState(
    constantUtils.getAllStaticMonthsFromApril()
  );
  const [unitData, setUnitData] = useState("");
  const [slotnames, setSlotnames] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(getPreviousMonth());
  const [yearsList, setYearsList] = useState(["2021", "2022", "2023", "2024"]);
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [selectedMonthTodData, setSelectedMonthTodData] = useState([]);
  const [uploadFileData, setUploadFileData] = useState(null);
  const [uploadFileResponse, setUploadFileResponse] = useState("");
  const [slotColors, setSlotColors] = useState({});
  const [slotConfigData, setslotConfigData] = useState("");

  const [showDownloadSampleExcelModal, setshowDownloadSampleExcelModal] =
    useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  const [loading, setLoading] = useState(false);

  const getMonthDays = () => {
    const daysInMonth = new Date(
      selectedYear,
      Object.keys(constantUtils.getAllStaticMonths()).indexOf(selectedMonth) +
        1,
      0
    ).getDate();
    //    console.log({ daysInMonth });
    const monthDays = Array(daysInMonth)
      .fill()
      .map((_, index) => {
        const date = new Date(
          selectedYear,
          Object.keys(constantUtils.getAllStaticMonths()).indexOf(
            selectedMonth
          ),
          index + 1
        );
        const day = date.toLocaleString("en-us", { weekday: "short" });
        const dayNumber = date.getDate();
        const month = date.toLocaleString("en-us", { month: "short" });
        return `${day} (${dayNumber} ${month})`;
      });
    //    console.log({ monthDays });
    return monthDays;
  };

  const [selectedMonthDays, setselectedMonthDays] = useState(getMonthDays());

  useEffect(() => {
    const monthDays = getMonthDays();
    setselectedMonthDays(monthDays);
    setData((prevData) => ({
      ...prevData,
      labels: monthDays,
    }));
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    // console.log({ dataArray });
    //    console.log(
    //   "===================data array updated==========================================="
    // );
    if (slotnames.length > 0) {
      setData((prevData) => ({
        ...prevData,
        datasets: dataArray,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        datasets: [],
      }));
    }
    //    console.log({ dataArray });
  }, [dataArray]);

  const [isOpenDropwonView, setIsOpenDropwonView] = useState(false);
  const handleEnergyMenuClick = () => {
    setIsOpenDropwonView(!isOpenDropwonView);
  };

  const getOrgname = (orgid) => {
    const orgName = organisationList.filter((org) => org._id === orgid);
    return orgName[0]?.name;
  };

  const getFactoryname = (id) => {
    const factory = factoryList.filter((factory) => factory._id === id);
    return factory[0]?.name;
  };
  const getUnitname = (id) => {
    const unit = unitList.filter((unit) => unit._id === id);
    return unit[0]?.name;
  };

  const getMeterNo = (id) => {
    const meter = meterList.filter((meter) => meter._id === id);
    return meter[0]?.meterNo;
  };
  useEffect(() => {
    const getOrgansiationList = async () => {
      const organisationList = await billService.getAllOrganisations();
      //      console.log({ organisationList });
      setOrganisationList(organisationList);
      if (organisationList.length > 0 && selectedOrg === "") {
        setSelectedOrg(organisationList[0]._id);
      }
    };
    getOrgansiationList();
  }, []);

  useEffect(() => {
    //    console.log({ mdg: "slected org changed", selectedOrg });
    if (selectedOrg !== "") {
      const getfactoryList = async () => {
        // const factoryList = await billService.getAllFactories(selectedOrg);
        const organisationTreeData = await billService.getOrganisationtree(
          selectedOrg
        );
        //        console.log({ organisationTreeData });
        if (organisationTreeData !== undefined) {
          if (organisationTreeData.factories.length !== 0) {
            const extractctedTreeData = organisationTreeData.factories.map(
              (factory) => {
                const factoryInfo = {
                  _id: factory._id,
                  name: factory.name,
                  units: factory.units.map((unit) => {
                    const unitInfo = {
                      _id: unit._id,
                      name: unit.name,
                      stateCode: unit.stateCode,
                      discomCode: unit.discomCode,
                      meters: unit.meters.map((meter) => ({
                        _id: meter._id,
                        consumerNo: meter.consumerNo,
                        meterNo: meter.meterNo,
                      })),
                    };
                    return unitInfo;
                  }),
                };
                return factoryInfo;
              }
            );
            //            console.log({ extractctedTreeData });
            setOrganisationTreeData(extractctedTreeData);
            setFactoryList(extractctedTreeData);

            if (extractctedTreeData.length > 0) {
              setSelectedFactory(extractctedTreeData[0]._id);
            } else {
              setSelectedFactory("");
            }
            setUnitList(extractctedTreeData[0].units);
            if (
              extractctedTreeData[0].units &&
              extractctedTreeData[0].units.length > 0
            ) {
              setSelectedUnit(extractctedTreeData[0].units[0]._id);
            } else {
              setSelectedUnit("");
            }
            setMeterList(extractctedTreeData[0].units[0].meters);
            if (
              extractctedTreeData[0].units[0].meters &&
              extractctedTreeData[0].units[0].meters.length > 0
            ) {
              setSelectedMeter(extractctedTreeData[0].units[0].meters[0]._id);
            } else {
              setSelectedMeter("");
            }
          } else {
            setFactoryList([]);
            setSelectedFactory("");
            setMeterList([]);
            setSelectedMeter("");
            setUnitList([]);
            setSelectedUnit("");
          }
        }
      };
      getfactoryList();
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (selectedFactory !== "") {
      // setunit list
      const factorydata = organisationTreeData.filter(
        (factory) => factory._id === selectedFactory
      );
      //      console.log({ factorydata });

      setUnitList(factorydata[0].units);
      setSelectedUnit(factorydata[0].units[0]._id);
      setMeterList(factorydata[0].units[0].meters);
      setSelectedMeter(factorydata[0].units[0].meters[0]._id);
    } else {
      setUnitList([]);
      setSelectedUnit("");
      setMeterList([]);
      setSelectedMeter("");
    }
  }, [selectedFactory]);

  useEffect(() => {
    let filteredUnits = [];
    if (selectedUnit !== "") {
      organisationTreeData.forEach((factory) => {
        factory.units.filter((unit) => {
          if (unit._id === selectedUnit) {
            filteredUnits.push(unit);
          }
        });
      });
      //      console.log({ filteredUnits });
      if (filteredUnits[0].meters.length > 0) {
        setMeterList(filteredUnits[0].meters);
        setSelectedMeter(filteredUnits[0].meters[0]._id);
      } else {
        setMeterList([]);
        setSelectedMeter("");
      }
    } else {
      setMeterList([]);
      setSelectedMeter("");
    }
  }, [selectedUnit]);

  useEffect(() => {
    // console.log({ selectedMonthTodData });
    // console.log({
    //   selectedYear,
    //   selectedYear1: selectedMonthTodData["year"],
    //   selectedMonth,
    //   selectedMonth1: selectedMonthTodData["month"],
    //   selectedMeter,
    //   selectedMeter1: selectedMonthTodData["meter"],
    // });
    if (
      selectedMonthTodData["year"] === selectedYear &&
      selectedMonthTodData["month"].toLowerCase() ===
        selectedMonth.toLowerCase() &&
      selectedMonthTodData["meter"] === selectedMeter
    ) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setLoading(false);
    }
    if (selectedMonthTodData !== undefined) {
      setDataArray(selectedMonthTodData.finalData);
    }
  }, [selectedMonthTodData]);

  const getTodData = async () => {
    //    console.log("tod data is being called...");
    const todData = await billService.getSpecificDayTodData(
      selectedYear,
      selectedMonth?.toLowerCase(),
      selectedMeter,
      slotnames,
      slotColors,
      slotConfigData
    );
    //    console.log({ todData });
    if (todData) {
      setSelectedMonthTodData(todData);
    }
  };

  useEffect(() => {
    //    console.log({
    //   useeffectData: "selectedMonth or selected meter data is chaged",
    // });
    //    console.log({
    //   selectedOrg,
    //   selectedFactory,
    //   selectedUnit,
    //   selectedMeter,
    //   selectedYear,
    //   selectedMonth,
    // });
    if (
      selectedOrg !== "" &&
      selectedFactory !== "" &&
      selectedUnit !== "" &&
      selectedMeter !== "" &&
      selectedYear !== "" &&
      selectedMonth !== "" &&
      slotnames.length > 0
    ) {
      setCustomDataAdded(false);
      getTodData();
    } else {
      setSelectedMonthTodData([]);
      setDataArray([]);
      setSlotColors([]);
    }
  }, [selectedMeter, selectedYear, selectedMonth, slotnames]);
  const [customDataAdded, setCustomDataAdded] = useState(false);

  useEffect(() => {
    // console.log({ selectedMonthTodData });
  }, [selectedMonthTodData]);

  useEffect(() => {
    //    console.log({ selectedMeter });
  }, [selectedMeter]);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  function convertTo6x31Array(data) {
    // Transpose the array
    const transposedData = data[0]
      .slice(1)
      .map((_, colIndex) => data.map((row) => row[colIndex + 1]));

    // // Remove the header row
    // transposedData.shift();
    const updatedData = transposedData.map((rowdata) => rowdata.slice(1));

    return updatedData;
  }

  //   const handleFileChange = async (e) => {
  //     //    console.log("first");
  //     const file = e.target.files[0];
  //     try {
  //       setCustomDataAdded(true);
  //       const rows = await readXlsxFile(file);
  //       //      console.log(rows);
  //       const updatedArray = convertTo6x31Array(rows);

  //       setDataArray(updatedArray);
  //     } catch (error) {
  //       //      console.log("Error reading Excel file:", error);
  //     }
  //   };

  const uploadFile = async (e) => {
    try {
      //      console.log({ uploadFileData: uploadFileData, selectedMeter });
      const result = await billService.uploadFileData(
        uploadFileData,
        selectedMeter
      );
      setUploadFileResponse(result);
    } catch (error) {
      //      console.log("Error reading Excel file:", error);
    }
  };

  const handleDownloadExcel = () => {
    const fileName = "sampleCustomisedExcelsheet.xlsx";
    const fileUrl = process.env.PUBLIC_URL + "/" + fileName;
    //    console.log(fileUrl);
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleDownloadHalfHourExcel = () => {
    const fileName = "halfhourslot.xlsx";
    const fileUrl = process.env.PUBLIC_URL + "/" + fileName;
    //    console.log(fileUrl);
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleDownloadhourExcel = () => {
    const fileName = "oneHourSlot.xlsx";
    const fileUrl = process.env.PUBLIC_URL + "/" + fileName;
    //    console.log(fileUrl);
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleEditQuationnavigation = () => {
    if (selectedUnit !== "") {
      navigate(`/update-unit-setting/${selectedUnit}`);
    }
  };

  useEffect(() => {
    if (unitData !== "") {
      const configData = JSON.parse(localStorage.getItem("config"));
      const statecode = unitData[0]["stateCode"];
      const disComCode = unitData[0]["discomCode"];
      //      console.log({ configData: configData });
      //      console.log({ statecode });
      //      console.log({ disComCode });
      if (
        configData &&
        configData.slotConfig &&
        configData.slotConfig[statecode] &&
        configData.slotConfig[statecode][disComCode] &&
        configData.slotConfig[statecode][disComCode]["slotName"] !==
          undefined &&
        configData.slotConfig[statecode][disComCode]["slotColor"] !== undefined
      ) {
        const getSlotNames = Object.keys(
          configData.slotConfig[statecode][disComCode]["slotName"]
        );
        //      console.log({ getSlotNames });
        setSlotnames(getSlotNames);

        const slotColorsData =
          configData.slotConfig[statecode][disComCode]["slotColor"];
        const allSlotTimeData = configData.slotConfig[statecode][disComCode];
        setslotConfigData(allSlotTimeData);
        //    console.log({ slotColorsData });
        setSlotColors(slotColorsData);
      } else {
        setSlotnames([]);
        setSlotColors([]);
      }
    }
  }, [unitData]);

  useEffect(() => {}, []);

  const getUnitData = (id) => {
    //    console.log({unitList})
    const unitData = unitList.filter((unit) => unit._id === id);

    //    console.log({ unitData });
    setUnitData(unitData);
    return unitData;
  };

  useEffect(() => {
    //    console.log({ unitData });
  }, [unitData]);

  useEffect(() => {
    if (selectedUnit !== "") {
      getUnitData(selectedUnit);
    }
  }, [selectedUnit]);

  function convertDateString(dateString) {
    // Extract day, month, and year from the string
    var dayMonthYear = dateString.match(/\(([^)]+)\)/)[1].split(" ");
    var day = dayMonthYear[0];
    var month = dayMonthYear[1];
    var year = selectedYear.toString(); // Assuming the year is 2024

    // // Create a new Date object with the extracted day, month, and year
    var newDate = new Date(`${year}-${month}-${day}`);

    // // Format the new date
    var options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    var formattedDate = newDate.toLocaleDateString("en-US", options);
    //    // console.log({ newDate });
    return formattedDate;
  }

  useEffect(() => {
    // console.log({slotConfigData})
  }, [slotConfigData]);

  return (
    <div className="max-w-[1920px] mt-[58px] lg:mt-[100px] sm:pr-[50px]">
      <div className="relative ">
        <div className="hidden sm:block">
          {/* <AnalyticsSidebar /> */}
          <Sidebar type={2} />
        </div>
        {/* </div>
    <div className={`${isSidebarOpen ? "ml-[320px]" : "ml-[150px]"}`}> */}
        <div data-tab-content="" className={`sm:pb-[20px] sm:ml-28 mx-[10px]`}>
          <div>
            <div className="md:flex py-2 md:py-0 items-center">
              <div className=" grid grid-cols-2 md:grid-cols-7 w-full my-5 gap-[20px]">
                <div className={` w-full`}>
                  <ASelect
                    label={"Organisation"}
                    options={organisationList?.map((org) => {
                      return { label: org.name, value: org._id };
                    })}
                    onChange={(e) => setSelectedOrg(e.target.value)}
                    value={selectedOrg}
                  />
                </div>

                <div className={` w-full`}>
                  {" "}
                  <ASelect
                    label={"Factory"}
                    options={factoryList?.map((factory) => {
                      return { label: factory.name, value: factory._id };
                    })}
                    onChange={(e) => setSelectedFactory(e.target.value)}
                    value={selectedFactory}
                  />
                </div>
                <div className={` w-full`}>
                  <ASelect
                    label={"Unit"}
                    options={unitList?.map((unit) => {
                      return { label: unit.name, value: unit._id };
                    })}
                    onChange={(e) => setSelectedUnit(e.target.value)}
                    value={selectedUnit}
                  />
                </div>
                <div className={` w-full`}>
                  <ASelect
                    label={"Consumer No"}
                    options={meterList?.map((meter) => {
                      return { label: meter.consumerNo, value: meter._id };
                    })}
                    onChange={(e) => setSelectedMeter(e.target.value)}
                    value={selectedMeter}
                  />
                </div>
                <div className={` w-full`}>
                  <ASelect
                    label={"Year"}
                    options={yearsList?.map((meter) => {
                      return { label: meter, value: meter };
                    })}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    value={selectedYear}
                  />
                </div>
                <div className={` w-full`}>
                  <ASelect
                    label={"Month"}
                    options={Object.keys(monthlist)?.map((meter) => {
                      return { label: meter, value: meter };
                    })}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    value={selectedMonth}
                  />
                </div>
              </div>
              <div className="flex gap-2 sm:gap-4 cursor-pointer items-center">
                <div className="relative group">
                  {/* <div className={`absolute bottom-8 hidden  z-50 py-2 bg-green-600 px-2 text-white rounded-lg group-hover:block`}>
              Download Sample Excel Data
            </div> */}
                  <FontAwesomeIcon
                    size="xl"
                    icon={faDownload}
                    style={{ color: "black" }}
                    onClick={() =>
                      setshowDownloadSampleExcelModal(
                        !showDownloadSampleExcelModal
                      )
                    }
                  />
                </div>
                {showDownloadSampleExcelModal ? (
                  <div className="fixed md:top-[25%] z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-2 text-center sm:block sm:p-0">
                      <div
                        className="fixed inset-0 transition-opacity"
                        aria-hidden="true"
                      >
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                      </div>

                      <div
                        className="inline-block align-bottom bg-white rounded-lg  text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xs sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                      >
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                id="modal-headline"
                              >
                                Download Sample Excel sheets
                              </h3>
                              <div className="mt-7 flex justify-center flex-col gap-5 w-full text-blue-400">
                                <div
                                  onClick={handleDownloadExcel}
                                  className="border-gray-400 border flex items-center justify-around rounded-lg px-5 shadow-lg py-2"
                                >
                                  <div>15 Min timeSlot</div>
                                  <div>
                                    {" "}
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faDownload}
                                      style={{ color: "black" }}
                                    />
                                  </div>
                                </div>
                                <div
                                  onClick={handleDownloadHalfHourExcel}
                                  className="border-gray-400 border flex items-center justify-around rounded-lg px-5 shadow-lg py-2"
                                >
                                  <div>30 Min timeSlot</div>
                                  <div>
                                    {" "}
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faDownload}
                                      style={{ color: "black" }}
                                    />
                                  </div>
                                </div>
                                <div
                                  onClick={handleDownloadhourExcel}
                                  className="border-gray-400 border flex items-center  justify-around rounded-lg px-5 shadow-lg py-2"
                                >
                                  <div>1 Hour timeSlot</div>
                                  <div>
                                    {" "}
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faDownload}
                                      style={{ color: "black" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 flex justify-center ">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() =>
                              setshowDownloadSampleExcelModal(false)
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="relative group">
                  <div className="absolute bottom-8 right-0 hidden z-50 py-2 bg-green-600 px-2 text-white rounded-lg group-hover:block">
                    Update Unit Custom Data
                  </div>

                  <FontAwesomeIcon
                    size="xl"
                    icon={faEdit}
                    style={{ color: "black" }}
                    onClick={handleEditQuationnavigation}
                  />
                </div>
                <UploadExelPopUp
                  organisationList={organisationList}
                  setSelectedOrg={setSelectedOrg}
                  selectedOrg={selectedOrg}
                  factoryList={factoryList}
                  setSelectedFactory={setSelectedFactory}
                  selectedFactory={selectedFactory}
                  unitList={unitList}
                  setSelectedUnit={setSelectedUnit}
                  selectedUnit={selectedUnit}
                  meterList={meterList}
                  setSelectedMeter={setSelectedMeter}
                  selectedMeter={selectedMeter}
                  setUploadFileData={setUploadFileData}
                  uploadFile={uploadFile}
                  uploadFileData={uploadFileData}
                  uploadFileResponse={uploadFileResponse}
                  setUploadFileResponse={setUploadFileResponse}
                />
                <div className="flex justify-center sm:text-xs items-center w-[100px] ">
                  <BackButton smpy={"sm:py-2"} />
                </div>
              </div>
            </div>
            <div className="flex w-full justify-center">
              <div
                className={`sm:w-[70%] flex  flex-col shadow-[0_4px_4px_0_#00000040] border border-[#00000040] rounded-[30px] w-full`}
              >
                <div className={`px-[20px] sm:px-[35px]`}>
                  <div className={` flex justify-between    py-[25px]  w-full`}>
                    <div className="flex flex-col gap-[5px]">
                      <div>
                        {" "}
                        Daywise : Estimated Tod Slot Wise Energy : Discom{" "}
                      </div>
                      <div className="text-gray-600 py-2 hidden  md:flex   md:flex-row px-6 capitalize text-[11px]">
                        <div
                          className={`${selectedOrg === "" ? "hidden" : ""}`}
                        >
                          <span className="text-gray-400 capitalize">
                            {getOrgname(selectedOrg)}{" "}
                          </span>
                          <span>(org) </span>
                        </div>
                        &ensp;
                        <div>
                          &ensp;
                          <FontAwesomeIcon
                            icon={faForward}
                            className=" text-gray-300 text-sm font-medium"
                          />
                          <span className="text-gray-400 capitalize">
                            {getFactoryname(selectedFactory)}{" "}
                          </span>
                          <span>(factory) </span>
                        </div>
                        &ensp;
                        <div>
                          &ensp;
                          <FontAwesomeIcon
                            icon={faForward}
                            className=" text-gray-300 text-sm font-medium"
                          />
                          <span className="text-gray-400 capitalize">
                            {getUnitname(selectedUnit)}{" "}
                          </span>
                          <span>(unit) </span>
                          &ensp;
                        </div>
                        <div
                          className={`${selectedMeter === "" ? "hidden" : ""}`}
                        >
                          <FontAwesomeIcon
                            icon={faForward}
                            className=" text-gray-300 text-sm font-medium"
                          />{" "}
                          &ensp;
                          <span className="text-gray-400 capitalize">
                            {getMeterNo(selectedMeter)}{" "}
                          </span>
                          <span>(meter) </span>
                        </div>
                      </div>
                    </div>
                    <div className="cursor-pointer flex gap-[20px]">
                      {/* <button onClick={() => setFull(true)}>
            <FontAwesomeIcon icon={faExpand} />
          </button> */}
                      <CustomTooltop
                        disable={
                          constantUtils.getGraphToolTipData().customised
                            .disableToolTip
                        }
                        text={
                          constantUtils.getGraphToolTipData().customised.text
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <LegendComponent
                      data={data}
                      slotConfigData={slotConfigData}
                      columns={"grid-cols-6"}
                    />
                  </div>
                </div>
                {!loading ? (
                  <div className=" px-[35px]  pb-[25px]">
                    <Bar data={data} options={options} />
                    <div className="text-gray-400 flex text-center w-full justify-center">
                      {customDataAdded
                        ? `Custom Data`
                        : `${selectedYear} ${selectedMonth} `}{" "}
                    </div>{" "}
                  </div>
                ) : (
                  <div className=" flex min-h-[340px] flex-col justify-center items-center">
                    <div>
                      <LoaderComponent />
                    </div>
                    <div>Data Loading please wait</div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex justify-center">
              <div className="sm:w-[70%]   pb-[25px]">
                <div className="w-full flex flex-col mt-10 justify-center">
                  <div className="min-h-[40px] shadow-md rounded-md mb-1 border hidden md:flex border-gray-300">
                    <div className="text-gray-600 py-2 flex px-6  capitalize text-[14px]">
                      <div className={`${selectedOrg === "" ? "hidden" : ""}`}>
                        <span className="text-gray-400 capitalize">
                          {getOrgname(selectedOrg)}{" "}
                        </span>
                        <span>(org) </span>
                      </div>
                      &ensp;
                      <div>
                        &ensp;
                        <FontAwesomeIcon
                          icon={faForward}
                          className=" text-gray-300 text-sm font-medium"
                        />
                        <span className="text-gray-400 capitalize">
                          {getFactoryname(selectedFactory)}{" "}
                        </span>
                        <span>(factory) </span>
                      </div>
                      &ensp;
                      <div>
                        &ensp;
                        <FontAwesomeIcon
                          icon={faForward}
                          className=" text-gray-300 text-sm font-medium"
                        />
                        <span className="text-gray-400 capitalize">
                          {getUnitname(selectedUnit)}{" "}
                        </span>
                        <span>(unit) </span>
                        &ensp;
                      </div>
                      <div
                        className={`${selectedMeter === "" ? "hidden" : ""}`}
                      >
                        <FontAwesomeIcon
                          icon={faForward}
                          className=" text-gray-300 text-sm font-medium"
                        />{" "}
                        &ensp;
                        <span className="text-gray-400 capitalize">
                          {getMeterNo(selectedMeter)}{" "}
                        </span>
                        <span>(meter) </span>
                      </div>
                    </div>
                  </div>

                  {!loading ? (
                    <div style={{ overflowX: "auto" }}>
                      <table className="text-center  shadow-[0_4px_4px_0_#00000040] rounded-xl  border-t-2 border-l-2 border-r-2 border-gray-300] w-full">
                        <thead className=" min-h-[100px]  shadow-md  ">
                          <tr className="rounded-xl text-[14px]    min-h-[105px]">
                            <th className="py-5 border-r  border-gray-300">
                              Day/Date
                            </th>
                            {slotnames.map((slot) => (
                              <th className=" border-r border-gray-300">
                                {slot} (kwh)
                              </th>
                            ))}

                            {/* <th className=" border-r border-gray-300">G1 (kwh)</th>
                  <th className=" border-r border-gray-300">Y (kwh)</th>
                  <th className=" border-r border-gray-300">G2 (kwh)</th>
                  <th className=" border-r border-gray-300">R (kwh)</th>
                  <th>B2 (kwh)</th> */}
                          </tr>
                        </thead>
                        <tbody className="rounded-xl border-none">
                          {dataArray &&
                          dataArray.length > 0 &&
                          slotnames.length > 0 ? (
                            <>
                              {selectedMonthDays.map((_, dayIndex) => (
                                <tr className="" key={dayIndex}>
                                  <td className=" border-t border-gray-300  text-[14px]   px-4 py-2">{` ${convertDateString(
                                    _
                                  )}`}</td>
                                  {dataArray.map((row, rowIndex) => (
                                    <td
                                      className="text-center text-[14px]   border border-1-[#f2f2f2] py-2"
                                      key={rowIndex}
                                    >
                                      {row["data"][dayIndex]}
                                      {/*       <input
                                type="number"
                                value={}
                                className="text-center"
                                onChange={(e) => {
                                  const newDataArray = [...dataArray];
                                  newDataArray[rowIndex]["data"][dayIndex] =
                                    Number(e.target.value);
                                  setDataArray(newDataArray);
                                }}
                              /> */}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr className=" text-center capitalize w-full">
                              <td colSpan={7} className="w-full py-8 ">
                                data not found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div
                      className="min-h-[700px] flex flex-col justify-center items-center 
                  shadow-[0_4px_4px_0_#00000040] rounded-xl  border-t-2 border-l-2 border-r-2 border-gray-300]"
                    >
                      <div>
                        <LoaderComponent />
                      </div>
                      <div>Data Loading please wait</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomisedDataDayView;
