import axios from "axios";
import jwtDecode from "jwt-decode";

const apiUrl = process.env.REACT_APP_API_URL;
const user = JSON.parse(localStorage.getItem('user'));
const token = user?.result?.token

export const userLogin = async (userData) => {
    try {
        const response = await axios.post(`${apiUrl}/user/login`, userData);
        if (response.status === 200) {
            localStorage.setItem("user", JSON.stringify(response.data));
            const token = response?.data?.result?.token;
            let decodedToken = null;
            if (token) {
                decodedToken = jwtDecode(token);
                localStorage.setItem("userId", decodedToken?._id);
            }
            return response.data;
        } else {
            return new Error("Failed to log in");
        }
    } catch (error) {
        console.error("Login Error:", error);
        return error;
    }
}

export const sendOTP = async (id) => {
    const payload = {
        "userId": id
    }

    try {
        const response = await axios.post(`${apiUrl}/user/sendOTP`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
        });
        return response?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const sendWelcomeEmail = async (id) => {
    const payload = {
        "userId": id
    }

    try {
        const response = await axios.post(`${apiUrl}/user/sendWelcomeEmail`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
        });
        return response?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const sendCredentialEmail = async (id,password) => {
    const payload = {
        "userId": id,
        password
    }

    try {
        const response = await axios.post(`${apiUrl}/user/sendCredentialEmail`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
        });
        return response?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}



export const sendOtpForgotPassword = async (email) => {
    const payload = {
        "email": email
    }

    try {
        const response = await axios.post(`${apiUrl}/user/sendOtpForgotPassword`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
        });
        return response?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const verifyOTP = async (userId, otp) => {
    const payload = {
        "userId": userId,
        "otp": otp
    }
    try {
        const response = await axios.post(`${apiUrl}/user/verifyOTP`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const verifyOtpForgotPassword = async (email, otp) => {
    const payload = {
        "email": email,
        "otp": otp
    }
    try {
        const response = await axios.post(`${apiUrl}/user/verifyOtpForgotPassword`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const getUserById = async (id) => {
    try {
        const response = await axios.get(`${apiUrl}/user/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const updateUserById = async (id, formData) => {

    try {
        const response = await axios.put(`${apiUrl}/user/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
        console.error('Error updating user:', error);
        return false;
    }
}

export const createUser = async (payload) => {
    try {
        const response = await axios.post(`${apiUrl}/user`, payload);
        return response?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export const setNewPassword = async (email, password) => {
    const payload = {
        'email': email,
        'password': password
    }
    try {
        const response = await axios.post(`${apiUrl}/user/setNewPassword`, payload, {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}
