import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getData, postData } from "../../api/apiDataExchange";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import CustomTooltop from "../../components/tooltips/CustomTooltop";

const RecommendationNew = ({
  selectedMeterId,
  isOpen,
  closePopover,
  isClosing,
  setIsOpen,
  startDatefromParent,
  endDatefromParent,
}) => {
  const today = new Date();
  const navigate = useNavigate();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  const fiscalYearStartMonth = 4;
  const lastFiscalYearStartMonth =
    currentMonth >= fiscalYearStartMonth
      ? fiscalYearStartMonth
      : fiscalYearStartMonth - 12;

  //   getting 2022 change to 2023

  const lastFiscalYearStartYear = currentYear - 1;

  const lastFiscalYearEndMonth = fiscalYearStartMonth - 1;
  const lastFiscalYearEndYear =
    currentMonth >= fiscalYearStartMonth ? currentYear : currentYear - 1;
  const [showSavings, setShowSavings] = useState(false);
  const [stateCode, setStateCode] = useState();
  const [disableFlag, setDisableFlag] = useState(false);

  function formatAsINR(amount) {
    if (amount === null) {
      return 0;
    }
    const numericAmount =
      typeof amount === "string" ? parseFloat(amount) : amount;

    if (isNaN(numericAmount)) {
      return 0;
      // throw new Error("Invalid amount");
    }

    return numericAmount.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    });
  }

  const formatDateToString = (date) => {
    if (date) {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${month}/${year}`;
    }
  };

  const [activeTab, setActiveTab] = useState(0);
  const [meter, setMeter] = useState("");
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [startDate, setStartDate] = useState(startDatefromParent);
  const [endDate, setEndDate] = useState(endDatefromParent);

  const [meterList, setMeterList] = useState([]);
  const [org, setOrg] = useState("");
  const [organisationList, setOrganisationList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [savingsThroughGreen, setSavingsThroughGreen] = useState(0);
  // const [savingsThroughGreenMeterWise, setSavingsThroughGreenMeterWise] = useState([]);
  const [savingEnergyLoader, setSavingEnergyLoader] = useState(true);
  const [filterTrigger, setFilterTrigger] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [priceType, setPriceType] = useState("constantprice");
  const [meterActiveTab, setMeterActiveTab] = useState();
  const location = useLocation();
  const [filters, setFilters] = useState({
    startDate: formatDateToString(startDate),
    endDate: formatDateToString(endDate),
    meterId: meter,
    priceType: priceType,
  });
  const [meterWRTState, setMeterWRTState] = useState([]);
  const [options, setOptions] = useState([]);
  const [tabData, setTabData] = useState([]);

  useEffect(() => {
    const newOptions = meterWRTState.flatMap((e) => {
      if (e.stateCode === stateCode) {
        return e.meters.map((meter) => ({
          label: meter.formattedConsumerName,
          value: meter._id,
          consumerNo: meter.consumerNo,
          discom: meter.discom,
          unit: meter.unitname,
        }));
      }
      return [];
    });

    setMeter(newOptions);
    setFilters((prev) => {
      return { ...prev, meterId: newOptions.map((e) => e.value) };
    });

    setOptions(newOptions);
  }, [stateCode]);

  useEffect(() => {
    const getdata = async () => {
      const payload = {
        buyerId: localStorage.getItem("userId"),
      };
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        payload
      );

      if (response?.data?.data?.results?.length > 0) {
        setOrg(response?.data?.data?.results[0]?._id);
        setOrganisationList(response?.data?.data?.results);
        setFilters({
          ...filters,
          organisationId: response?.data?.data?.results[0]?._id,
        });
      }
    };
    getdata();
  }, []);

  useEffect(() => {
    setInitialLoad(true);
  }, []);

  const convertToStartDate = (dateString) => {
    const [month, year] = dateString.split("/");
    const firstDayOfMonth = moment
      .utc(`${year}-${month}-01`, "YYYY-MM-DD")
      .startOf("day");
    return firstDayOfMonth.toDate();
  };

  const convertToEndDate = (dateString) => {
    const [month, year] = dateString.split("/");
    const date = moment
      .utc(`${year}-${month}-01`, "YYYY-MM-DD")
      .endOf("day")
      .toDate();
    return date;
  };

  useEffect(() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      const orgFromQuery = query.get("org");
      const stateFromQuery = query.get("state");
      const consumersFromQuery = query.get("consumers");
      const startDateFromQuery = query.get("startDate");
      const endDateFromQuery = query.get("endDate");
      let updatedFilters = {};

      if (orgFromQuery) {
        updatedFilters.organisationId = orgFromQuery;
        setOrg(orgFromQuery);
      }

      if (stateFromQuery) {
        updatedFilters.stateCode = stateFromQuery;
        setStateCode(stateFromQuery);
      }

      if (consumersFromQuery) {
        const comsumer = consumersFromQuery
          .replace(/[\[\]]/g, "")
          .split(",")
          .map((item) => item.trim());

        updatedFilters.meterId = comsumer;

        let formattedConsumers;
        if (Array.isArray(comsumer)) {
          formattedConsumers = comsumer.map((id) => {
            const matchingMeter = meterList.find((meter) => meter._id === id);
            return {
              label: matchingMeter ? matchingMeter.consumerNo : "",
              value: id,
            };
          });
        } else {
          console.error(
            "consumersFromQuery is not an array:",
            consumersFromQuery
          );
          formattedConsumers = [];
        }
        setMeter(formattedConsumers);
      }

      if (startDateFromQuery) {
        updatedFilters.startDate = startDateFromQuery;
        setStartDate(convertToStartDate(startDateFromQuery));
      }

      if (endDateFromQuery) {
        updatedFilters.endDate = endDateFromQuery;
        setEndDate(convertToEndDate(endDateFromQuery));
      }

      setFilters((prevFilters) => ({ ...prevFilters, ...updatedFilters }));
    }
  }, [location.search, meterList]);

  const [metersCalculationSteps, setMetersCalculationSteps] = useState([]);
  const [savingsHeaders, setSavingsHeaders] = useState([]);
  const [savingHeadersTitle, setSavingHeadersTitle] = useState([]);
  const [noDataFoundMessage, setNoDataFoundMessage] = useState("");

  useEffect(() => {
    const getdata = async () => {
      let data = {};
      if (startDatefromParent) {
        data.startDate = formatDateToString(startDatefromParent);
      }
      if (endDatefromParent) {
        data.endDate = formatDateToString(endDatefromParent);
      }
      if (filters.organisationId) {
        data.organisationId = filters.organisationId;
      }
      if (filters.stateCode) {
        data.stateCode = filters.stateCode;
      }

      if (selectedMeterId) {
        data.meterId = selectedMeterId;
      }

      if (selectedMeterId) {
        setSavingEnergyLoader(true);

        const response = await postData(
          `${process.env.REACT_APP_API_URL}/recommendation/saveEnergy?pricetype=${priceType}&calculations_steps=1`,
          data
        );

        if (response?.data?.statusCode === 200) {
          if (response.data.data.length > 0) {
            setNoDataFoundMessage("");
            setMetersCalculationSteps(response.data.data);
            setMeterActiveTab(response?.data?.data[0]?.consumerNo);
            setTabData((prevData) => {
              const updatedData = { ...(prevData || {}) };
              const consumerNo = response.data.data[0].consumerNo;
              updatedData[consumerNo] = {
                ...response.data.data[0],
              };
              return updatedData;
            });

            const totalSaveGreenEnergy = response?.data?.data?.reduce(
              (total, entry) => total + entry.saveGreenEnergy,
              0
            );

            setSavingsThroughGreen(
              totalSaveGreenEnergy >= 0 ? totalSaveGreenEnergy : 0
            );
            //   setSavingsThroughGreenMeterWise(response?.data?.data);
            setSavingEnergyLoader(false);
            setInitialLoad(false);
            setFilterTrigger(false);
          } else {
            setMetersCalculationSteps(response.data.data);
            // setMetersArray(response.data.data);
            setNoDataFoundMessage(response?.data.message);
            setSavingEnergyLoader(false);
            setInitialLoad(false);
            setFilterTrigger(false);
          }
        } else {
          setSavingEnergyLoader(false);
          setInitialLoad(false);
          setFilterTrigger(false);
        }
      } else {
        setSavingsThroughGreen(0);

        // setSavingEnergyLoader(false);

        setFilterTrigger(false);
      }
    };
    if (selectedMeterId && startDatefromParent && endDatefromParent) {
      console.log({ selectedMeterId });
      getdata();
    }
  }, [selectedMeterId, startDatefromParent, endDatefromParent]);

  const setMeterId = (id) => {
    const allSelectedMeter = meterList.filter((meter) => meter._id === id);

    setSelectedMeter(allSelectedMeter);
  };

  useEffect(() => {
    console.log({ meteid: `meterid updated to ${selectedMeterId}` });
    console.log({ meterList: meterList });
    if (meterList.length > 0) {
      setMeterId(selectedMeterId);
    }
  }, [selectedMeterId, meterList]);

  useEffect(() => {
    if ((stateCode || meter.length > 0) && !initialLoad) {
      setFilters({
        ...filters,
        meterId: [],
        stateCode: "",
      });
    }
    // if (!initialLoad) {

    setStateCode("");
    setMeter([]);

    // }

    const getTreeViewData = async () => {
      if (org !== "") {
        const response = await getData(
          `${process.env.REACT_APP_API_URL}/organisation/tree/${org}`
        );

        let data = {
          organisationId: filters.organisationId,
        };
        if (filters?.startDate) {
          data.startDate = filters.startDate;
        }
        if (filters?.endDate) {
          data.endDate = filters.endDate;
        }

        if (selectedMeterId) {
          data.meterId = selectedMeterId;
        }
        setSavingEnergyLoader(true);
        const [consumerList] = await Promise.all([
          postData(
            `${process.env.REACT_APP_API_URL}/recommendation/getRecommendationConsumers`,
            data
          ),
        ]);

        const allFactories = response?.data?.factories?.flatMap(
          (factories) => factories || []
        );
        setFactoryList(allFactories);

        const allUnits = allFactories.flatMap((factory) => factory.units);
        setSelectedUnit(allUnits[0]?._id);
        const unitsData = allUnits
          ?.map((unit) => ({
            name: unit?.name,
            coordinates: unit?.location?.coordinates,
          }))
          .filter(
            (unit) =>
              unit?.coordinates &&
              unit?.coordinates.length === 2 &&
              unit?.coordinates?.every(
                (coord) => coord !== null && coord !== undefined
              )
          );
        setUnitData(unitsData);
        // const extractedInfo = allUnits?.map(unit => unit.state) || [];

        const extractedInfo = Array.from(
          new Map(allUnits.map((unit) => [unit.stateCode, unit.state])).values()
        );
        const extractedInfoSorted = extractedInfo.sort((a, b) => {
          if (a.code === "IN-MH") return -1;
          if (b.code === "IN-MH") return 1;

          return a.name.localeCompare(b.name);
        });

        setStateList(extractedInfoSorted);

        // Collect all meters directly from the selected organizations
        const allMeters = allFactories.flatMap((factory) =>
          factory.units.flatMap((unit) => unit.meters)
        );

        const allStateCodes = extractedInfo.map((stateCode) => stateCode?.code);
        console.log({ allMeters });

        // filter by state
        let allMetersWRTState = allUnits.map((e) => {
          return {
            stateCode: e.stateCode,
            meters: e.meters,
          };
        });

        allMetersWRTState.sort((a, b) => {
          if (a.stateCode === "IN-MH") return -1; // a comes before b
          if (b.stateCode === "IN-MH") return 1; // b comes before a
          return 0; // no change in order for other stateCodes
        });

        const requiredMeters = consumerList?.data?.data?.results;

        const consumerNosList2 = requiredMeters.map((item) => item?.consumerNo);
        const filteredList = allMetersWRTState.filter((item) =>
          item?.meters?.some((meter) =>
            consumerNosList2?.includes(meter.consumerNo)
          )
        );

        const sortedData2 = filteredList.reduce((acc, curr) => {
          const existingState = acc.find(
            (item) => item?.stateCode === curr?.stateCode
          );
          if (existingState) {
            existingState.meters = existingState.meters.concat(curr.meters);
          } else {
            acc.push({ ...curr });
          }

          return acc;
        }, []);

        console.log({ sortedData2 });
        setMeterWRTState(sortedData2);

        const allMeterIdsWRTState = sortedData2[0]?.meters.map(
          (meter) => meter._id
        );

        setMeter(
          sortedData2[0]?.meters
            ?.sort((a, b) => {
              if (a?.discom < b?.discom) return -1;
              if (a?.discom > b?.discom) return 1;
              return 0; // If discomCodes are equal
            })
            .flatMap((meter) => ({
              label: meter.consumerNo,
              value: meter._id,
              consumerNo: meter.consumerNo,
            }))
        );

        setStateCode(allStateCodes[0]);
        if (allMeterIdsWRTState && allMeterIdsWRTState[0]) {
          setFilters({
            ...filters,
            stateCode: allStateCodes[0],
            meterId: selectedMeterId,
          });
        }
        setMeterList(allMeters);
      }
      setSavingEnergyLoader(false);
    };
    getTreeViewData();
  }, [org]);

  const [stateList, setStateList] = useState([]);
  const tabs = [
    "Savings from Group Captive",
    // "Savings from Exchange Transaction",
    // "Savings from 3rd Party Transaction",
    "Savings from RTS",
  ];

  useEffect(() => {
    setSavingsHeaders([]);
    setSavingHeadersTitle([]);
    if (tabData[meterActiveTab]?.calculations_steps) {
      const meterWiseCalculationSteps = tabData[meterActiveTab];
      const DataForMonthsKeys =
        meterWiseCalculationSteps?.calculations_steps?.savingsFromGroupCaptive;
      if (DataForMonthsKeys) {
        const dataForMonths = getMonthYearArray(
          startDatefromParent,
          endDatefromParent,
          Object.keys(DataForMonthsKeys)
        );
        const headerForsavings = ["Months", ...Object.keys(DataForMonthsKeys)];
        setSavingsHeaders(headerForsavings);
        setSavingHeadersTitle(["Months", ...dataForMonths, "Total"]);
      }
    }
  }, [metersCalculationSteps, meterActiveTab]);

  const getMonthYearArray = (startDate, endDate, monthArray) => {
    // Define the mapping of month names to indices
    const monthMap = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    const start = new Date(startDate);
    const end = new Date(endDate);
    const result = [];

    let current = new Date(start);

    while (current <= end) {
      const monthIndex = current.getMonth();
      const monthName = Object.keys(monthMap).find(
        (key) => monthMap[key] === monthIndex
      );
      const formattedMonth = monthArray.find((month) =>
        month.startsWith(monthName)
      );
      const year = current.getFullYear();

      if (formattedMonth) {
        result.push(`${formattedMonth}-${year}`);
      }

      // Move to the next month
      current.setMonth(current.getMonth() + 1);
    }

    return result;
  };

  function convertDateFormat(dateString) {
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const [month, year] = dateString.split("-");
    const monthNumber = monthMap[month];

    if (!monthNumber || !year) {
      throw new Error("Invalid date format. Expected format: Jan-2023");
    }

    return `${monthNumber}/${year}`;
  }
  const handleView = (conNo, monthandYear) => {
    console.log({ monthandYear });
    const updatedMonthAndYear = convertDateFormat(monthandYear);
    window.open(
      `/compare-ocr-bills?conNo=${conNo}&startDate=${updatedMonthAndYear}&endDate=${updatedMonthAndYear}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className=" mt-0 pt-[58px] sm:pt-[90px] lg:h-screen">
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className={`"bg-white rounded-lg shadow-lg  px-2 relative pt-2 pb-2 w-full transform transition-transform duration-500 animate-slide-in"  ${
              isClosing ? "animate-slide-out" : "animate-slide-in"
            }  `}
          >
            <div className="mt-1 bg-gray-100 p-4  rounded-md w-[100%]   ">
              <div className="border-b py-0.5 border-gray-200 ">
                <div
                  onClick={closePopover}
                  className="flex absolute top-6 right-8  justify-end cursor-pointer"
                >
                  <FontAwesomeIcon
                    className="justify-end"
                    size="xl"
                    color="black"
                    icon={faClose}
                  />
                </div>
                <nav
                  className="-mb-px flex space-x-1  overflow-x-auto"
                  aria-label="Tabs"
                >
                  {meter && meter.length > 0 && (
                    <div
                      className={`px-4 py-2 border-b-2 cursor-pointer font-medium min-w-[300px] text-sm shadow ${
                        meterActiveTab === meter.consumerNo
                          ? "border-b-indigo-500 bg-white text-indigo-600 shadow-md"
                          : "border-transparent bg-gray-200 text-gray-500 hover:text-gray-700 hover:border-gray-300"
                      }`}
                    >
                      <div className="inline">
                        Consumer No : {`${selectedMeter[0]?.consumerNo}`}{" "}
                      </div>
                      <div className="text-[12px] uppercase">
                        {" "}
                        {`${selectedMeter[0]?.unitname} (${selectedMeter[0]?.discom})`}{" "}
                      </div>
                      {/*         <div>Discom : {`${meter.discom}`}</div>
                        <div>Unit : {`${meter.unit}`}</div> */}
                    </div>
                  )}
                </nav>

                {savingEnergyLoader === true ? (
                  ""
                ) : (
                  <nav className="-mb-px flex space-x-1 mt-3" aria-label="Tabs">
                    {tabs.map((tab, index) => (
                      <button
                        key={index}
                        className={`px-4 py-2 border-b-2 font-medium min-w-[150px] text-sm rounded-t-md shadow ${
                          activeTab === index
                            ? "border-b-indigo-500 bg-white text-indigo-600 shadow-md border rounded-t-xl"
                            : "border-transparent bg-gray-200 text-gray-500 hover:text-gray-700 hover:border-gray-300"
                        }`}
                        onClick={() => setActiveTab(index)}
                      >
                        {tab}
                      </button>
                    ))}
                  </nav>
                )}
              </div>

              {savingEnergyLoader === true ? (
                <div className="w-[50%] h-[50%] mx-auto my-[10%]">
                  <LoaderComponent />
                </div>
              ) : savingsHeaders.length === 0 ? (
                <div className="text-center text-xl my-[10%]">
                  <h2 className="text-lg">No Recommendations Found</h2>
                  <br />
                  <p className="text-sm">
                    {noDataFoundMessage
                      ? noDataFoundMessage
                      : `We couldn't find any recommendations based on your current
                  search criteria.`}
                  </p>
                </div>
              ) : (
                <>
                  <div className="w-full">
                    <div className=" w-full h-[80vh] md:h-[75vh] upload-bills overflow-auto mt-1 rounded-xl bg-white p-3  ">
                      <div className="relative">
                        <div className="text-md md:text-xl p-2 bg-gray-100  font-bold w-full">
                          Electricity Bill Current
                        </div>
                        <div className="overflow-x-auto">
                          <table className="w-full">
                            <thead>
                              <tr>
                                {savingHeadersTitle &&
                                  savingHeadersTitle?.map((header, index) => (
                                    <th
                                      key={index}
                                      className={`px-2 cursor-pointer py-2 w-[8.3%] text-[12px] md:text-md bg-gray-200 text-black${
                                        index === 0 ? "text-left" : "text-right"
                                      }`}
                                      onClick={() =>
                                        handleView(
                                          selectedMeter[0]?.consumerNo,
                                          header
                                        )
                                      }
                                    >
                                      <div
                                        className={`flex gap-2 ${
                                          header === "Months"
                                            ? "justify-start"
                                            : "justify-end"
                                        }`}
                                      >
                                        <span
                                          className={`${
                                            header !== "Months" &&
                                            header !== "Total"
                                              ? "underline"
                                              : ""
                                          }`}
                                        >
                                          {header}
                                        </span>
                                        {header !== "Months" &&
                                          header !== "Total" && (
                                            <CustomTooltop
                                              size="lg"
                                              text={`Click to view bill for ${header}`}
                                            ></CustomTooltop>
                                          )}
                                      </div>
                                    </th>
                                  ))}
                              </tr>
                            </thead>
                            <tbody>
                              {
                                // metersCalculationSteps.length > 0 && metersCalculationSteps.map((meterWiseCalculationSteps, rowIndex) => {
                                savingsHeaders.length > 0 &&
                                  tabData[meterActiveTab]?.calculations_steps &&
                                  (() => {
                                    const meterWiseCalculationSteps =
                                      tabData[meterActiveTab];

                                    const savingsFromGroupCaptive =
                                      meterWiseCalculationSteps
                                        .calculations_steps
                                        ?.savingsFromGroupCaptive;
                                    const savingsRows = [
                                      [
                                        "Demand Charges",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.demandCharges || 0
                                              );
                                            }
                                            return 0;
                                          }),
                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.demandCharges || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "Wheeling Charge",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.wheelingCharges || 0
                                              );
                                            }
                                            return 0;
                                          }),

                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.wheelingCharges || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "Energy Charges",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.energyCharges || 0
                                              );
                                            }
                                            return 0;
                                          }),
                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.energyCharges || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "TOD Charges",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.todCharges || 0
                                              );
                                            }
                                            return 0;
                                          }),
                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.todCharges || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "FAC",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.facCharges || 0
                                              );
                                            }
                                            return 0;
                                          }),

                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.facCharges || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "Electricity Duty",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.electricityDuty || 0
                                              );
                                            }
                                            return 0;
                                          }),
                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.electricityDuty || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "Bulk Consumption Rebate",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.bulkConsumptionRebate || 0
                                              );
                                            }
                                            return 0;
                                          }),
                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.bulkConsumptionRebate || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "Tax On Sale",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.taxOnSale || 0
                                              );
                                            }
                                            return 0;
                                          }),
                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.taxOnSale || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "Textile Subsidy",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.textileSubsidy || 0
                                              );
                                            }
                                            return 0;
                                          }),

                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.textileSubsidy || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "GOM Subsidy",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.gomSubsidy || 0
                                              );
                                            }
                                            return 0;
                                          }),

                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.gomSubsidy || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "Incremental Consumption Rebate",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.incrementalConsumptionRebate ||
                                                0
                                              );
                                            }
                                            return 0;
                                          }),
                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.incrementalConsumptionRebate ||
                                                  0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                      [
                                        "Total Current Bill",
                                        ...savingsHeaders
                                          .slice(1)
                                          .map((month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.charges || 0
                                              );
                                            }
                                            return 0;
                                          }),
                                        savingsHeaders
                                          .slice(1)
                                          .reduce((sum, month) => {
                                            if (
                                              savingsFromGroupCaptive &&
                                              savingsFromGroupCaptive[month]
                                            ) {
                                              return (
                                                sum +
                                                (savingsFromGroupCaptive[month]
                                                  .discomExistingBill
                                                  ?.charges || 0)
                                              );
                                            }
                                            return sum;
                                          }, 0),
                                      ],
                                    ];

                                    return (
                                      savingsRows &&
                                      savingsRows.map((savingsRow, rowIndex) =>
                                        activeTab === 0 ? (
                                          <tr
                                            key={rowIndex}
                                            style={
                                              rowIndex ===
                                              savingsRows.length - 1
                                                ? {
                                                    backgroundColor: "#9FFF33",
                                                    borderTop:
                                                      "2px solid #F0F1EF",
                                                  }
                                                : {}
                                            }
                                          >
                                            {savingsRow.map(
                                              (cell, cellIndex) => (
                                                <td
                                                  key={cellIndex}
                                                  className={`px-2 py-2 w-[8.3%]c text-sm ${
                                                    cellIndex === 0
                                                      ? "font-bold"
                                                      : "text-right"
                                                  }`}
                                                >
                                                  {typeof cell === "number"
                                                    ? formatAsINR(
                                                        Math.ceil(cell)
                                                      )
                                                    : cell}
                                                </td>
                                              )
                                            )}
                                          </tr>
                                        ) : (activeTab === 1 ||
                                            activeTab === 2 ||
                                            activeTab === 3) &&
                                          rowIndex ===
                                            savingsRows.length - 1 ? (
                                          <tr
                                            key={rowIndex}
                                            style={
                                              rowIndex ===
                                              savingsRows.length - 1
                                                ? {
                                                    backgroundColor: "#9FFF33",
                                                    borderTop:
                                                      "2px solid #F0F1EF",
                                                  }
                                                : {}
                                            }
                                          >
                                            {savingsRow.map(
                                              (cell, cellIndex) => (
                                                <td
                                                  key={cellIndex}
                                                  className={`px-2 py-2 w-[8.3%]c text-sm ${
                                                    cellIndex === 0
                                                      ? "font-bold"
                                                      : "text-right"
                                                  }`}
                                                >
                                                  {typeof cell === "number"
                                                    ? formatAsINR(
                                                        Math.ceil(cell)
                                                      )
                                                    : cell}
                                                </td>
                                              )
                                            )}
                                          </tr>
                                        ) : (
                                          ""
                                        )
                                      )
                                    );
                                  })()
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="text-md md:text-xl p-2 bg-gray-100 mt-4 font-bold w-full">
                        {activeTab === 0
                          ? "Discom Bill Post Group Captive"
                          : // : activeTab === 1
                          // ? "Discom Bill Post Power Exchange"
                          // : activeTab === 2
                          // ? "Discom Bill Post Third Party"
                          activeTab === 2
                          ? "Discom Bill Post RTS"
                          : ""}
                      </div>
                      <div className="overflow-x-auto">
                        <table className="w-full">
                          <thead>
                            <tr>
                              {savingHeadersTitle &&
                                savingHeadersTitle.map(
                                  (header, index) =>
                                    index < savingHeadersTitle.length - 1 && (
                                      <th
                                        key={index}
                                        className={`text-[12px] md:text-md px-2 py-2 w-[8.3%] bg-gray-200 text-black ${
                                          index === 0
                                            ? "text-left"
                                            : "text-right"
                                        }`}
                                      >
                                        {header}
                                      </th>
                                    )
                                )}
                            </tr>
                          </thead>

                          <tbody>
                            {
                              // metersCalculationSteps.length > 0 && metersCalculationSteps.map((meterWiseCalculationSteps, rowIndex) => {
                              savingsHeaders.length > 0 &&
                                tabData[meterActiveTab]?.calculations_steps &&
                                (() => {
                                  const meterWiseCalculationSteps =
                                    tabData[meterActiveTab];
                                  const savingsFromGroupCaptive =
                                    activeTab === 0
                                      ? meterWiseCalculationSteps
                                          .calculations_steps
                                          ?.savingsFromGroupCaptive
                                      : // : activeTab === 1
                                      // ? meterWiseCalculationSteps
                                      //     .calculations_steps
                                      //     ?.savingsFromExchangeTransactions
                                      // : activeTab === 2
                                      // ? meterWiseCalculationSteps
                                      //     .calculations_steps
                                      //     ?.savingsFromThirdParty
                                      activeTab === 1
                                      ? meterWiseCalculationSteps
                                          .calculations_steps?.savingsFromRTS
                                      : "";
                                  const activeSaving =
                                    activeTab === 0
                                      ? "totalDiscomBillAfterSolar"
                                      : activeTab === 1
                                      ? // ? "totalDiscomBillAfterPowerExchange"
                                        // : activeTab === 2
                                        // ? "totalDiscomBillAfterThirdParty"
                                        // : activeTab === 3
                                        "totalDiscomBillAfterSolar"
                                      : "";
                                  const savingsRows = [
                                    [
                                      "Demand Charges",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].demandCharges || 0
                                            : 0;
                                        }),
                                    ],

                                    [
                                      "Wheeling Charges",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].wheelingCharges || 0
                                            : 0;
                                        }),
                                    ],

                                    [
                                      "Energy Charges",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].energyCharges || 0
                                            : 0;
                                        }),
                                    ],

                                    [
                                      "TOD Charges",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].todCharges || 0
                                            : 0;
                                        }),
                                    ],
                                    [
                                      "Fac Charges",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].facCharges || 0
                                            : 0;
                                        }),
                                    ],
                                    [
                                      "Electricity Duty",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].electricityDuty || 0
                                            : 0;
                                        }),
                                    ],

                                    [
                                      "Bulk Consumption Rebate",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].bulkConsumptionRebate || 0
                                            : 0;
                                        }),
                                    ],

                                    [
                                      "Tax On Sale",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].taxOnSale || 0
                                            : 0;
                                        }),
                                    ],
                                    [
                                      "Textile Subsidy",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].textileSubsidy || 0
                                            : 0;
                                        }),
                                    ],
                                    [
                                      "GOM Subsidy",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].gomSubsidy || 0
                                            : 0;
                                        }),
                                    ],
                                    [
                                      "Prompt Payment Discount",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].promptPaymentDiscount || 0
                                            : 0;
                                        }),
                                    ],
                                    [
                                      "Incremental Consumption Rebate",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].incrementalConsumptionRebate ||
                                                0
                                            : 0;
                                        }),
                                    ],
                                    [
                                      "Tax Collection At Source",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].taxCollectionAtSource || 0
                                            : 0;
                                        }),
                                    ],

                                    [
                                      "Total Bill",
                                      ...savingsHeaders
                                        .slice(1)
                                        .map((month) => {
                                          return savingsFromGroupCaptive &&
                                            savingsFromGroupCaptive.hasOwnProperty(
                                              month
                                            ) &&
                                            savingsFromGroupCaptive[month] &&
                                            savingsFromGroupCaptive[month][
                                              activeSaving
                                            ]
                                            ? savingsFromGroupCaptive[month][
                                                activeSaving
                                              ].charges || 0
                                            : 0;
                                        }),
                                    ],
                                  ];

                                  return savingsRows.map(
                                    (savingsRow, rowIndex) => (
                                      <tr
                                        key={rowIndex}
                                        style={
                                          rowIndex === savingsRows.length - 1
                                            ? {
                                                backgroundColor: "#9FFF33",
                                                borderTop: "2px solid #F0F1EF",
                                              }
                                            : {}
                                        }
                                      >
                                        {savingsRow.map((cell, cellIndex) => (
                                          <td
                                            key={cellIndex}
                                            className={`px-2 py-2 w-[8.3%] text-sm ${
                                              cellIndex === 0
                                                ? "font-bold"
                                                : "text-right"
                                            }`}
                                          >
                                            {typeof cell === "number"
                                              ? cell === 0 &&
                                                (meterWiseCalculationSteps.totalSavingsFromGroupCaptive ===
                                                  0 ||
                                                  meterWiseCalculationSteps.totalSavingsFromRTS ===
                                                    0)
                                                ? "N/A"
                                                : formatAsINR(Math.ceil(cell))
                                              : cell}
                                          </td>
                                        ))}
                                      </tr>
                                    )
                                  );
                                })()
                            }
                          </tbody>
                        </table>
                      </div>

                      <div className="text-md md:text-xl p-2 bg-gray-100 mt-4 font-bold w-full">
                        {activeTab === 0
                          ? "Group Captive Solar OA Bill"
                          : activeTab === 1
                          ? "RTS Captive Bill"
                          : ""}
                      </div>
                      {(activeTab === 0 || activeTab === 1) && (
                        <div className="overflow-x-auto">
                          <table className="w-[100%]">
                            <thead>
                              <tr>
                                {savingHeadersTitle &&
                                  savingHeadersTitle.map(
                                    (header, index) =>
                                      index < savingHeadersTitle.length - 1 && (
                                        <th
                                          key={index}
                                          className={`text-[12px] md:text-md px-2 py-2 w-[8.3%] bg-gray-200 text-black ${
                                            index === 0
                                              ? "text-left"
                                              : "text-right"
                                          }`}
                                        >
                                          {header}
                                        </th>
                                      )
                                  )}
                              </tr>
                            </thead>

                            <tbody>
                              {
                                // metersCalculationSteps.length > 0 && metersCalculationSteps.map((meterWiseCalculationSteps, rowIndex) => {

                                savingsHeaders.length > 0 &&
                                  tabData[meterActiveTab]?.calculations_steps &&
                                  (() => {
                                    const meterWiseCalculationSteps =
                                      tabData[meterActiveTab];
                                    const savingsFromGroupCaptive =
                                      activeTab === 0
                                        ? meterWiseCalculationSteps
                                            .calculations_steps
                                            .savingsFromGroupCaptive
                                        : activeTab === 1
                                        ? // ? meterWiseCalculationSteps
                                          //     .calculations_steps
                                          //     .savingsFromExchangeTransactions
                                          // : activeTab === 2
                                          // ? meterWiseCalculationSteps
                                          //     .calculations_steps
                                          //     .savingsFromThirdParty
                                          // : activeTab === 3
                                          meterWiseCalculationSteps
                                            .calculations_steps.savingsFromRTS
                                        : "";

                                    const activeSaving =
                                      activeTab === 0
                                        ? "totalBillOfGroupCaptiveSolar"
                                        : activeTab === 1
                                        ? // ? "totalBillOfPowerExchangeData"
                                          // : activeTab === 2
                                          // ? "totalBillOfThirdParty"
                                          // : activeTab === 3
                                          "totalBillOfGroupCaptiveSolar"
                                        : "";

                                    const savingsRows =
                                      activeTab === 0 || activeTab === 1
                                        ? [
                                            [
                                              "Energy Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map((month) => {
                                                  if (
                                                    savingsFromGroupCaptive &&
                                                    savingsFromGroupCaptive.hasOwnProperty(
                                                      month
                                                    ) &&
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ][activeSaving]
                                                  ) {
                                                    return (
                                                      savingsFromGroupCaptive[
                                                        month
                                                      ][activeSaving]
                                                        .energyCharge || 0
                                                    );
                                                  }
                                                  return 0; // Default value if not present
                                                }),
                                            ],
                                            [
                                              "Transmission Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map((month) => {
                                                  if (
                                                    savingsFromGroupCaptive &&
                                                    savingsFromGroupCaptive.hasOwnProperty(
                                                      month
                                                    ) &&
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ][activeSaving]
                                                  ) {
                                                    return (
                                                      savingsFromGroupCaptive[
                                                        month
                                                      ][activeSaving]
                                                        .transmissionCharge || 0
                                                    );
                                                  }
                                                  return 0;
                                                }),
                                            ],
                                            [
                                              "Wheeling Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map((month) => {
                                                  if (
                                                    savingsFromGroupCaptive &&
                                                    savingsFromGroupCaptive.hasOwnProperty(
                                                      month
                                                    ) &&
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ][activeSaving]
                                                  ) {
                                                    return (
                                                      savingsFromGroupCaptive[
                                                        month
                                                      ][activeSaving]
                                                        .wheelingCharge || 0
                                                    );
                                                  }
                                                  return 0;
                                                }),
                                            ],
                                            [
                                              "Monthly Operating Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map((month) => {
                                                  if (
                                                    savingsFromGroupCaptive &&
                                                    savingsFromGroupCaptive.hasOwnProperty(
                                                      month
                                                    ) &&
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ][activeSaving]
                                                  ) {
                                                    return (
                                                      savingsFromGroupCaptive[
                                                        month
                                                      ][activeSaving]
                                                        .operatingCharge || 0
                                                    );
                                                  }
                                                  return 0;
                                                }),
                                            ],
                                            [
                                              "Total Bill",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map((month) => {
                                                  if (
                                                    savingsFromGroupCaptive &&
                                                    savingsFromGroupCaptive.hasOwnProperty(
                                                      month
                                                    ) &&
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ][activeSaving]
                                                  ) {
                                                    return (
                                                      savingsFromGroupCaptive[
                                                        month
                                                      ][activeSaving].charges ||
                                                      0
                                                    );
                                                  }
                                                  return 0;
                                                }),
                                            ],
                                          ]
                                        : activeTab === 1
                                        ? [
                                            [
                                              "Energy Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.energyCharge
                                                ),
                                            ],
                                            [
                                              "CTU Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.chargesOfCTU
                                                ),
                                            ],
                                            [
                                              "Transmission Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.transmissionCharges
                                                ),
                                            ],
                                            [
                                              "Wheeling Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.wheelingCharges
                                                ),
                                            ],
                                            [
                                              "Cross Subsidy Surchage",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.crossSubsidySurchage
                                                ),
                                            ],
                                            [
                                              "Additional Surchage",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.additionalSurchage
                                                ),
                                            ],
                                            [
                                              "Exchange And Trader Fees",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.exchangeAndTraderFees
                                                ),
                                            ],
                                            [
                                              "Monthly Operating Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.monthlyOperatingCharge
                                                ),
                                            ],
                                            [
                                              "Total Bill",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]?.charges
                                                ),
                                            ],
                                          ]
                                        : activeTab === 2
                                        ? [
                                            [
                                              "Energy Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.energyCharge
                                                ),
                                            ],
                                            [
                                              "CTU Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.chargesOfCTU
                                                ),
                                            ],
                                            [
                                              "Transmission Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.transmissionCharges
                                                ),
                                            ],
                                            [
                                              "Wheeling Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.wheelingCharges
                                                ),
                                            ],
                                            [
                                              "Cross Subsidy Surchage",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.crossSubsidySurchage
                                                ),
                                            ],
                                            [
                                              "Additional Surchage",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.additionalSurchage
                                                ),
                                            ],
                                            [
                                              "Exchange And Trader Fees",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.exchangeAndTraderFees
                                                ),
                                            ],
                                            [
                                              "Monthly Operating Charges",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]
                                                      ?.monthlyOperatingCharge
                                                ),
                                            ],
                                            [
                                              "Total Bill",
                                              ...savingsHeaders
                                                .slice(1)
                                                .map(
                                                  (month) =>
                                                    savingsFromGroupCaptive[
                                                      month
                                                    ]?.[activeSaving]?.charges
                                                ),
                                            ],
                                          ]
                                        : "";

                                    return savingsRows.map(
                                      (savingsRow, rowIndex) => (
                                        <tr
                                          key={rowIndex}
                                          style={
                                            rowIndex === savingsRows.length - 1
                                              ? {
                                                  backgroundColor: "#9FFF33",
                                                  borderTop:
                                                    "2px solid #F0F1EF",
                                                }
                                              : {}
                                          }
                                        >
                                          {savingsRow.map((cell, cellIndex) => (
                                            <td
                                              key={cellIndex}
                                              className={`px-2 py-2 w-[8.3%] text-sm ${
                                                cellIndex === 0
                                                  ? "font-bold"
                                                  : "text-right"
                                              }`}
                                            >
                                              {typeof cell === "number"
                                                ? cell === 0 &&
                                                  (meterWiseCalculationSteps.totalSavingsFromGroupCaptive ===
                                                    0 ||
                                                    meterWiseCalculationSteps.totalSavingsFromRTS ===
                                                      0)
                                                  ? "N/A"
                                                  : formatAsINR(Math.ceil(cell))
                                                : cell}
                                            </td>
                                          ))}
                                        </tr>
                                      )
                                    );
                                  })()
                              }
                            </tbody>
                          </table>
                        </div>
                      )}

                      <div className="sticky bottom-[27px] md:bottom-8">
                        {showSavings && savingsHeaders.length > 0 && (
                          <div className="border-black rounded-sm border-2 mt-1">
                            <div className="flex flex-row justify-between bg-gray-300 px-2 items-center">
                              <div className="truncate w-4/5 text-md md:text-xl px-0 font-bold">
                                {activeTab === 0
                                  ? "Savings From Group Captive"
                                  : activeTab === 1
                                  ? // ? "Savings From Exchange Transaction"
                                    // : activeTab === 2
                                    // ? "Savings From Third Party Transaction"
                                    // : activeTab === 3
                                    "Savings from RTS"
                                  : ""}
                              </div>
                              <FontAwesomeIcon
                                className="text-black md:text-xl font-bold mt-1 px-1 cursor-pointer text-sm"
                                icon={faChevronUp}
                                onClick={() => setShowSavings(false)}
                              />
                            </div>

                            <div className="overflow-x-auto">
                              <table className="w-full">
                                <thead>
                                  <tr>
                                    {savingHeadersTitle &&
                                      savingHeadersTitle.map(
                                        (header, index) =>
                                          index <
                                            savingHeadersTitle.length - 1 && (
                                            <th
                                              key={index}
                                              className={`text-[12px] md:text-md px-2 py-2 w-[8.3%] bg-gray-200 text-black ${
                                                index === 0
                                                  ? "text-left"
                                                  : "text-right"
                                              }`}
                                            >
                                              {header}
                                            </th>
                                          )
                                      )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {savingsHeaders.length > 0 &&
                                    tabData[meterActiveTab]
                                      ?.calculations_steps &&
                                    (() => {
                                      const meterWiseCalculationSteps =
                                        tabData[meterActiveTab];

                                      const savingsValues =
                                        activeTab === 0
                                          ? meterWiseCalculationSteps
                                              .calculations_steps
                                              .savingsFromGroupCaptive
                                          : activeTab === 1
                                          ? // ? meterWiseCalculationSteps
                                            //     .calculations_steps
                                            //     .savingsFromExchangeTransactions
                                            // : activeTab === 2
                                            // ? meterWiseCalculationSteps
                                            //     .calculations_steps
                                            //     .savingsFromThirdParty
                                            // : activeTab === 3
                                            meterWiseCalculationSteps
                                              .calculations_steps.savingsFromRTS
                                          : "";

                                      let savingsRows;
                                      if (savingsValues) {
                                        savingsRows = [
                                          [
                                            "Total Savings",
                                            ...savingsHeaders
                                              ?.slice(1)
                                              ?.map(
                                                (month) =>
                                                  savingsValues[month]?.charges
                                              ),
                                          ],
                                        ];
                                      } else {
                                        savingsRows = [];
                                      }

                                      return savingsRows?.map(
                                        (savingsRow, rowIndex) => (
                                          <tr
                                            key={rowIndex}
                                            style={
                                              rowIndex ===
                                              savingsRows.length - 1
                                                ? {
                                                    backgroundColor: "#9FFF33",
                                                    borderTop:
                                                      "2px solid #F0F1EF",
                                                  }
                                                : {}
                                            }
                                          >
                                            {savingsRow.map(
                                              (cell, cellIndex) => (
                                                <td
                                                  key={cellIndex}
                                                  className={`px-2 py-0 md:py-2 w-[8.3%] text-sm md:text-md ${
                                                    cellIndex === 0
                                                      ? "font-bold"
                                                      : "text-right"
                                                  }`}
                                                >
                                                  {typeof cell === "number"
                                                    ? formatAsINR(
                                                        Math.ceil(cell)
                                                      )
                                                    : cell}
                                                </td>
                                              )
                                            )}
                                          </tr>
                                        )
                                      );
                                    })()}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="sticky md:bottom-8 border-2 border-black rounded-sm "></div>
                      <div className="sticky bottom-0 py-1 md:py-2 px-2 bg-[green] mt-2 font-bold w-full text-[white] text-sm md:text-[22px] flex flex-row justify-between items-center">
                        <div className="truncate md:overflow-visible md:whitespace-normal md:text-clip w-4/5">
                          {activeTab === 0
                            ? "Total Savings From Group Captive :"
                            : activeTab === 1
                            ? // ? "Total Savings From Exchange Transaction :"
                              // : activeTab === 2
                              // ? "Total Savings From Third Party Transaction :"
                              // : activeTab === 3
                              "Total Savings From RTS :"
                            : ""}
                        </div>
                        <div>
                          {
                            // metersCalculationSteps.length > 0 && metersCalculationSteps.map((meterWiseCalculationSteps, rowIndex) =>
                            savingsHeaders.length > 0 &&
                              tabData[meterActiveTab]?.calculations_steps &&
                              (() => {
                                const meterWiseCalculationSteps =
                                  tabData[meterActiveTab];

                                const totalSavings =
                                  activeTab === 0
                                    ? meterWiseCalculationSteps.totalSavingsFromGroupCaptive
                                    : activeTab === 1
                                    ? // ? meterWiseCalculationSteps.totalSavingsFromExchangeTransactions
                                      // : activeTab === 2
                                      // ? meterWiseCalculationSteps.totalSavingsFromThirdParty
                                      // : activeTab === 3
                                      meterWiseCalculationSteps.totalSavingsFromRTS
                                    : "";
                                return (
                                  <div className="flex flex-row">
                                    {formatAsINR(totalSavings)}
                                    <FontAwesomeIcon
                                      className={`text-white text-sm text-right md:text-xl font-bold pl-0 md:pl-2 cursor-pointer pt-1 md:pt-0`}
                                      icon={
                                        showSavings
                                          ? faChevronDown
                                          : faChevronUp
                                      }
                                      onClick={() =>
                                        setShowSavings((prev) => !prev)
                                      }
                                    />
                                  </div>
                                );
                              })()
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecommendationNew;
